// React
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, FormHelperText, Radio, RadioGroup, TextField, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import ReactPhoneInput from 'react-phone-input-material-ui';

import AddressInput from './AddressInput';

// Date/Time UI
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getLocaleTimeZone } from '@ergonauts/ergo-algo-react/core/constants';

// Alerts
import { setError, setSuccess } from '../alerts';

// API
import { createGroup, updateGroup } from '../api';
import { isEmail, isValidPhoneNumber } from '@lexcelon/js-util/lib/validator';

// Constants
import { MAX_FILE_SIZE_MB } from '@ergonauts/ergo-algo-react/core/constants';

class GroupForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
      },
      groupName: '',
      contractExpiration: '',
      maxEvaluators: null,
      maxEvaluations: null,
      displayImagePath: '',
      contactName: '',
      contactEmail: '',
      contactPhoneNumber: '',
      formError: false,
      isLoading: false,
      addressStarted: false,
      evalueeCanViewReport: false,
      datePickerOpen: false
    };
  }

  componentDidMount() {
    if (this.props.group != null) {
      this.setState({
        groupName: this.props.group.getName() ?? '',
        contractExpiration: this.props.group.getContractExpiration() ?? '',
        maxEvaluators: this.props.group.getContractMaxEvaluators() ?? '',
        maxEvaluations: this.props.group.getContractMaxEvaluations() ?? '',
        contactName: this.props.group.getContactName() ?? '',
        contactEmail: this.props.group.getContactEmail() ?? '',
        contactPhoneNumber: this.props.group.getContactPhoneNumber() ?? '',
        address: {
          line1: this.props.group.getAddress()?.getLine1() ?? '',
          line2: this.props.group.getAddress()?.getLine2() ?? '',
          city: this.props.group.getAddress()?.getCity() ?? '',
          state: this.props.group.getAddress()?.getState() ?? '',
          zip: this.props.group.getAddress()?.getZip() ?? ''
        },
        evalueeCanViewReport: this.props.group.getEvalueeCanViewReport() ?? '',
      });
    }

    if (window && window.google) {
      var address1Field = document.querySelector('#line1');
      this.setState({
        autocomplete: new window.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components'],
          types: ['address'],
        })
      }, () => {
        this.state.autocomplete.addListener('place_changed', this.fillInAddress);
      });
    }

    this.fillInAddress = this.fillInAddress.bind(this);
  }

  fillInAddress() {
    if (this.state.autocomplete) {
      // Get the place details from the autocomplete object.
      const place = this.state.autocomplete.getPlace();
      let address1 = '';
      var city = '';
      var state = '';
      var zip = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code':
            zip = component.long_name;
            break;
          case 'locality':
            city = component.long_name;
            break;
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          default:
        }
      }

      this.setState({ address: { line1: address1, city: city, state: state, zip: zip } });

      // After filling the form with address components from the Autocomplete
      // prediction, set cursor focus on the second address line to encourage
      // entry of subpremise information such as apartment, unit, or floor number.
      var address2Field = document.querySelector('#line2');
      address2Field.focus();
    }
  }

  onChange = (e) => {
    if (this.state.address.line1 != '' || this.state.address.city != '' || this.state.address.state != '' || this.state.address.zip != '') {
      this.setState({ addressStarted: true });
    }
    else {
      this.setState({ addressStarted: false });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  hasValidForm = () => {
    if (this.state.contactName?.length > 255 || this.state.groupName?.length > 255) {
      setError('Error: Please enter a name less than 255 characters');
      return false;
    }

    if (this.state.contractExpiration?.invalid) {
      setError('Error: Please pick a valid date and time.');
      return false;
    }

    if (this.state.maxEvaluators < 0 || this.state.maxEvaluations < 0) {
      setError('Error: Please choose a number greater than 0');
      return false;
    }

    if (!isEmail(this.state.contactEmail) && this.state.contactEmail != '') {
      setError('Error: Invalid contact email');
      return false;
    }

    if (!isValidPhoneNumber(this.state.contactPhoneNumber) && this.state.contactPhoneNumber != '') {
      setError('Error: Invalid contact phone number');
      return false;
    }

    if ((this.state.address.line1 == '' || this.state.address.city == '' || this.state.address.state == '' || this.state.zip == '') && (this.state.line1 != '' || this.state.address.city != '' || this.state.address.state != '' || this.state.address.zip != '')) {
      setError('Error: Invalid address');
      return false;
    }

    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const validForm = this.hasValidForm();

    this.setState({
      formError: !validForm,
      isLoading: true
    });

    const {
      address,
      groupName,
      contractExpiration,
      maxEvaluators,
      maxEvaluations,
      contactName,
      contactEmail,
      contactPhoneNumber,
      displayImage,
      evalueeCanViewReport
    } = this.state;

    if (!validForm) {
      this.setState({ isLoading: false });
    }
    else {
      var form = {
        address: address,
        name: groupName,
        contractExpiration: contractExpiration,
        maxEvaluators: maxEvaluators,
        maxEvaluations: maxEvaluations,
        contactName: contactName,
        contactEmail: contactEmail,
        contactPhoneNumber: contactPhoneNumber,
        evalueeCanViewReport: evalueeCanViewReport,
        displayImage: displayImage
      };

      if (this.props.group == null) {
        createGroup(form).then(group => {
          this.setState({ isLoading: false });
          setSuccess('Successfully created group');
          this.props.onSuccess(group);
        }).catch(error => {
          setError(error ?? 'Error: Unable to create group');
          this.setState({ isLoading: false });
        });
      }
      else {
        updateGroup({ id: this.props.group?.id, ...form }).then(group => {
          this.setState({ isLoading: false });
          setSuccess('Successfully updated group');
          this.props.onSuccess(group);
        }).catch(error => {
          setError(error ?? 'Error: Unable to update group');
          this.setState({ isLoading: false });
        });
      }
    }
  };

  onImageInput = (e) => {
    // Set Image for Display
    var input = document.getElementById('displayImagePath');
    // get file size in megabytes, throw error if file is too large
    var size = input.files[0].size / 1e6;
    if (size >= MAX_FILE_SIZE_MB) {
      setError(`Error: Please select a file smaller than ${MAX_FILE_SIZE_MB}MB.`);
      this.setState({ displayImagePath: '' });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });

    var fReader = new FileReader();
    fReader.readAsDataURL(input.files[0]);
    this.setState({ displayImage: input.files[0] });
    fReader.onloadend = function (event) {
      document.getElementById('displayImage').innerHTML = '<image src=\'' + event.target.result + '\' style=\'height: 60px;\'/>';
    };
  };

  formChanged() {
    return !(this.props.group.getName() === this.state.groupName &&
      this.props.group.getContractExpiration() === this.state.contractExpiration &&
      this.props.group.getContractMaxEvaluators() == this.state.maxEvaluators &&
      this.props.group.getContractMaxEvaluations() == this.state.maxEvaluations &&
      this.props.group.getContactName() === this.state.contactName &&
      this.props.group.getContactEmail() === this.state.contactEmail &&
      this.props.group.getContactPhoneNumber() === this.state.contactPhoneNumber &&
      this.props.group.getAddress()?.getLine1() === this.state.address.line1 &&
      ((this.props.group.getAddress()?.getLine2() === null && this.state.address.line2 === '') || (this.props.group.getAddress()?.getLine2() == this.state.address.line2)) &&
      this.props.group.getAddress()?.getCity() === this.state.address.city &&
      this.props.group.getAddress()?.getState() === this.state.address.state &&
      this.props.group.getAddress()?.getZip() == this.state.address.zip &&
      this.props.group.getEvalueeCanViewReport() === this.state.evalueeCanViewReport &&
      this.state.displayImagePath === '');
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>

        {/* ----- Group Info ----- */}
        <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Group Info</Typography>

        <TextField
          required
          error={this.state.formError && (this.state.groupName == '' || this.state.groupName?.length > 255)}
          name='groupName'
          label='Group Name'
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.groupName}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <LocalizationProvider dateAdapter={AdapterLuxon}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <DatePicker
              renderInput={(props) =>
                <TextField
                  required
                  variant='filled'
                  style={{ width: '100%' }} {...props}
                  error={this.state.formError && this.state.contractExpiration == ''}
                  onClick={() => this.setState({ datePickerOpen: true })}
                />
              }
              open={this.state.datePickerOpen && !this.state.isLoading}
              name='contractExpiration'
              label='Contract Expiration Date'
              value={this.state.contractExpiration}
              onChange={(newValue) => {
                this.setState({
                  contractExpiration: newValue,
                  formError: false
                });
              }}
              onClose={() => this.setState({ datePickerOpen: false })}
              disabled={this.state.isLoading}
            />
            <Typography variant='body1' style={{ fontSize: '20px', fontWeight: 'bold', color: '#404040', marginLeft: '8px' }}>{getLocaleTimeZone()}</Typography>
          </div>
        </LocalizationProvider>
        <FormHelperText style={{ marginBottom: '10px' }}>Please note date/time above is scheduled in your current local time.</FormHelperText>

        <TextField
          required
          error={this.state.formError && this.state.maxEvaluators == null}
          name="maxEvaluators"
          label="Max Number of Evaluators"
          type='number'
          style={{ width: '100%' }}
          value={this.state.maxEvaluators}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          InputProps={{ inputProps: { min: 0, max: 1000000 } }}
        />
        <FormHelperText style={{ marginBottom: '1em' }}>Max number of evaluators for total contract duration</FormHelperText>

        <TextField
          required
          error={this.state.formError && this.state.maxEvaluations == null}
          name="maxEvaluations"
          label="Max Number of Evaluations"
          type='number'
          style={{ width: '100%' }}
          value={this.state.maxEvaluations}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          InputProps={{ inputProps: { min: 0, max: 1000000 } }}
        />
        <FormHelperText style={{ marginBottom: '1em' }}>Max number of evaluations for total contract duration</FormHelperText>

        <Typography style={{ marginBottom: '10px' }}>Group Logo</Typography>
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '0.3em', marginBottom: '0.3em' }}>
          <input disabled={this.state.isLoading} type="file" accept='.png,.jpg,.jpeg' id="displayImagePath" name="displayImagePath" ref={ref => this.fileInput = ref} label="Select Image" onChange={this.onImageInput} style={{ width: '60%' }} value={this.state.displayImagePath} />
          <div id="displayImage" />
        </div>

        {/* ----- Group Primary Contact ----- */}
        <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Group Primary Contact</Typography>
        <Typography variant='body1' style={{ marginBottom: '10px' }}>This contact information is for internal, manual use only.</Typography>

        <TextField
          required
          error={this.state.formError && this.state.contactName?.length > 255}
          name='contactName'
          label='Contact Name'
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.contactName}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <TextField
          required
          error={this.state.formError && (!isEmail(this.state.contactEmail) && this.state.contactEmail != '')}
          name='contactEmail'
          label='Contact Email'
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.contactEmail}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <ReactPhoneInput
          country='us'
          onlyCountries={['us']}
          value={this.state.contactPhoneNumber}
          onChange={(contactPhoneNumber) => this.setState({ contactPhoneNumber })}
          component={TextField}
          inputProps={{ variant: 'filled', required: true, label: 'Contact Phone Number', disabled: this.state.isLoading }}
          disableCountryCode={true}
          disableDropdown={true}
          placeholder='(702) 123-4567'
          containerStyle={{ width: '100%', marginBottom: '10px' }}
        />

        {/* ----- Group Location ----- */}
        <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '20px' }}>Group Location</Typography>

        <AddressInput
          isLoading={this.state.isLoading}
          onChange={(address) => this.setState({ address })}
          requiredFields={['line1', 'city', 'state', 'zip']}
          initialValue={this.props.group != null ? {
            line1: this.props.group.getAddress()?.getLine1() ?? '',
            line2: this.props.group.getAddress()?.getLine2() ?? '',
            city: this.props.group.getAddress()?.getCity() ?? '',
            state: this.props.group.getAddress()?.getState() ?? '',
            zip: String(this.props.group.getAddress()?.getZip()) ?? '',
          } : undefined}
        />

        Prevent Evaluee From Viewing Evaluation Report?
        <RadioGroup
          defaultValue={this.props.group?.getEvalueeCanViewReport() != null ? this.props.group?.getEvalueeCanViewReport()?.toString() : 'false'}
          name='evalueeCanViewReport'
          onChange={this.onChange}
        >
          <FormControlLabel disabled={this.state.isLoading} value='false' control={<Radio />} label='Yes' />
          <FormControlLabel disabled={this.state.isLoading} value='true' control={<Radio />} label='No' />
        </RadioGroup>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          {this.props.onClose != null &&
            <Button secondary disabled={this.state.isLoading} style={{ width: '49%', marginRight: '10px' }} onClick={this.props.onClose}>
              Cancel
            </Button>}

          <Button type='submit' isLoading={this.state.isLoading} disabled={this.props.group != null && !this.formChanged()} style={{ width: '49%' }}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

GroupForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  group: PropTypes.object,
  onClose: PropTypes.func
};

export default GroupForm;
