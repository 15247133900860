import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// API
import { getCoupon, toggleCouponActive } from '../../../api';

// Alerts
import { setError, setSuccess } from '../../../alerts';

// Components
import { LineItem } from '@lexcelon/react-util';
import { BackToButton, CouponForm } from '../../../components';

// Constants
import { ROUSING_RED } from '@ergonauts/ergo-algo-react/core/constants';
import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

class Coupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupon: null,
      redirect: false,
      inEditMode: false
    };

    this.onEditSuccess = this.onEditSuccess.bind(this);
  }

  componentDidMount() {
    this.loadCoupon();
  }

  loadCoupon() {
    const couponId = this.props.match?.params?.id;

    getCoupon(couponId).then(coupon => {
      this.setState({ coupon: coupon });
    }).catch(error => {
      setError(error ?? 'Error: unable to load coupon details.');

      this.setState({ redirect: true });
    });
  }

  toggleCouponStatus() {
    const newStatus = !this.state.coupon.isActive;

    toggleCouponActive({ id: this.state.coupon.id, isActive: newStatus }).then(() => {
      setSuccess('Successfully ' + (newStatus ? 'activated' : 'deactivated') + ' the coupon.');

      this.loadCoupon();
    }).catch((error) => {
      setError(error ?? 'Unable to toggle coupon status, please try again.');
    });
  }

  onEditSuccess() {
    this.setState({
      inEditMode: false
    });

    // Reload
    this.loadCoupon();
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/coupons'} />;
    }

    if (this.state.inEditMode) {
      return (
        <Container style={{ paddingTop: '20px' }}>
          <BackToButton to={'/coupons'} description='Coupons' />
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Edit Coupon</Typography>
          <CouponForm
            onSuccess={this.onEditSuccess}
            coupon={this.state.coupon}
            onClose={() => this.setState({ inEditMode: false })}
          />
        </Container>
      );
    }

    return (
      <Container style={{ paddingTop: '20px' }}>
        <BackToButton to={'/coupons'} description='Coupons' />
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>Coupon {this.state.coupon?.getCode()}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center' }}>Coupon</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: ROUSING_RED, fontStyle: 'italic' }}>{this.state.coupon?.getIsActive() ? 'Active' : 'Inactive'}</Typography>

        <LineItem
          value={enumToDisplayString(this.state.coupon?.getUse())}
          description='Use'
        />

        {this.state.coupon?.getUse() == 'TRAINING' && <LineItem
          value={this.state.coupon?.getSegment()?.getName()}
          description='Segment'
        />}

        {this.state.coupon?.getUse() == 'ERGONAR' && <LineItem
          value={this.state.coupon?.getErgonar() != null ?
            <Link to={'/training/ergonars/' + this.state.coupon?.getErgonar()?.getId()}>{this.state.coupon?.getErgonar()?.getName()}</Link> : null
          }
          description='Ergonar'
        />}

        {this.state.coupon?.getAmountOff() && <LineItem
          value={this.state.coupon?.getAmountOff() != null ? '$' + this.state.coupon?.getAmountOff() : null}
          description='Amount Off'
        />}

        {this.state.coupon?.getPercentOff() && <LineItem
          value={this.state.coupon?.getPercentOff() != null ? Math.round(this.state.coupon?.getPercentOff() * 100) + '%' : null}
          description='Percent Off'
        />}

        <LineItem
          value={this.state.coupon?.getCurrentUseCount()}
          description='Current Number of Uses'
        />

        <LineItem
          value={this.state.coupon?.getMaxUseCount()}
          description='Maximum Number of Uses'
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{
              margin: '10px',
              padding: '10px',
              width: (window.innerWidth < 700 ? '80%' : '40%')
            }}
            onClick={() => {
              this.setState({
                inEditMode: true
              });
            }}
            secondary
          >
            Edit Coupon
          </Button>

          <Button
            style={{
              margin: '10px',
              padding: '10px',
              width: (window.innerWidth < 700 ? '80%' : '40%')
            }}
            onClick={() => {
              this.toggleCouponStatus();
            }}
            danger
          >
            {this.state.coupon?.getIsActive() ? 'Set Inactive' : 'Set Active'}
          </Button>
        </div>

      </Container>
    );
  }
}

Coupon.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Coupon);
