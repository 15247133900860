import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import qs from 'qs';
import { Container, Typography } from '@mui/material';

// Alerts
import { setError } from '../../../alerts';

// Components
import { BackToButton, EvaluationForm } from '../../../components';

// API
import { getGroup, getPerson } from '../../../api';

class ScheduleEvaluation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupName: 'Group',
      evaluatorName: 'Evaluator',
    };

    this.groupId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.groupId;
    this.evaluatorId = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.evaluatorId;
  }

  componentDidMount() {
    if (this.groupId) {
      getGroup(this.groupId).then((group) => {
        this.setState({ groupName: group.getName() });
      }).catch((error) => {
        setError(error ?? 'Unable to load group.');
      });
    }

    if (this.evaluatorId) {
      getPerson(this.evaluatorId).then((person) => {
        this.setState({ evaluatorName: person.getFullName() });
      }).catch((error) => {
        setError(error ?? 'Unable to load evaluator.');
      });
    }
  }

  render() {
    return (
      <Container style={{ paddingTop: '20px' }}>
        <>
          {this.groupId && <BackToButton to={'/groups/' + this.groupId + '/evaluations'} description={this.state.groupName + '\'s Evaluations'} />}
          {this.evaluatorId && <BackToButton to={'/people/' + this.evaluatorId + '/evaluator/evaluations'} description={this.state.evaluatorName + '\'s Evaluations'} />}
          {this.groupId == null && this.evaluatorId == null && <BackToButton to={'/evaluations'} description='Evaluations' />}
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Schedule Evaluation</Typography>

          <EvaluationForm
            evaluatorId={this.evaluatorId}
            groupId={this.groupId}
          />
        </>
      </Container>
    );
  }
}

ScheduleEvaluation.propTypes = {
  location: PropTypes.object.isRequired
};

export default withRouter(ScheduleEvaluation);
