import React from 'react';
import PropTypes from 'prop-types';

import { LineItem } from '@lexcelon/react-util';

import { GENDER_OPTIONS } from '@ergonauts/ergo-algo-react/core/constants';

function ProfileInfoSection({ person }) {

  return (
    <>
      <div>
        <LineItem
          value={person?.getFirstName() + ' ' + person?.getLastName()}
          description='Name'
        />

        <LineItem
          value={person?.getEmail()}
          description='Email'
        />

        <LineItem
          value={person?.getPhoneNumber()}
          description='Phone Number'
        />

        <LineItem
          value={GENDER_OPTIONS.find(e => e.enum == person?.getGender())?.display}
          description='Gender'
        />

        <LineItem
          value={person?.getHeardAboutFrom()}
          description='Heard About From'
        />

        <LineItem
          value={person?.getMailingAddress() ? (person?.getMailingAddress()?.getLine1() + ' ' + (person?.getMailingAddress()?.getLine2() ? person?.getMailingAddress()?.getLine2() : '') + ' ' + person?.getMailingAddress()?.getCity() + ', ' + person?.getMailingAddress()?.getState() + ', ' + person?.getMailingAddress()?.getZip()) : null}
          description='Mailing Address'
        />
      </div>
    </>
  );
}

ProfileInfoSection.propTypes = {
  person: PropTypes.object
};

export default ProfileInfoSection;
