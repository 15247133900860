import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { EvaluatorCertificationTable } from '../../components';

// API
import { listEvaluatorCertifications } from  '../../api';

// Alerts
import { setError } from '../../alerts';

class Certifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      certifications: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listEvaluatorCertifications().then(({ results }) => {
      if (results != null) {
        this.setState({ certifications: results });
      }
      this.setState({ isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve certifications.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <EvaluatorCertificationTable certifications={this.state.certifications} isLoading={this.state.isLoading} />
    );
  }
}

Certifications.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Certifications);
