import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@lexcelon/react-util';
import { Checkbox, CircularProgress, FormControlLabel, MenuItem, TextField } from '@mui/material';

// API
import { createCoupon, listErgonars, listSegments, updateCoupon } from '../api';

// Alerts
import { setError, setSuccess } from '../alerts';

// Constants
import { MAX_INTEGER, COUPON_USES } from '@ergonauts/ergo-algo-react/core/constants';
export const COUPON_TYPES = [
  {
    display: 'Percent Off (%)',
    enum: 'PERCENT_OFF'
  },
  {
    display: 'Amount Off ($)',
    enum: 'AMOUNT_OFF'
  }
];
class CouponForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      segments: [],
      ergonars: [],
      code: '',
      use: '',
      type: '',
      amountOff: '',
      percentOff: '',
      segmentId: 0,
      ergonarId: 0,
      maxUseCount: '',
      isActive: true,
      formError: false,
      isLoading: true
    };
  }

  // If ergonar is passed in we should populate our state with its values.
  componentDidMount() {
    if (this.props.coupon != null) {
      this.setState({
        id: this.props.coupon?.getId(),
        code: this.props.coupon?.getCode() ?? '',
        use: this.props.coupon?.getUse() ?? '',
        amountOff: this.props.coupon?.getAmountOff() ?? '',
        percentOff: Math.round(this.props.coupon?.getPercentOff() * 100) ?? '',
        segmentId: this.props.coupon?.getSegment()?.getId() ?? 0,
        ergonarId: this.props.coupon?.getErgonar()?.getId() ?? 0,
        maxUseCount: this.props.coupon?.getMaxUseCount() ?? '',
        isActive: this.props.coupon?.getIsActive()
      });

      if (this.props.coupon?.getPercentOff() == null) {
        this.setState({ type: 'AMOUNT_OFF' });
      }

      if (this.props.coupon?.getAmountOff() == null) {
        this.setState({ type: 'PERCENT_OFF' });
      }
    }

    listSegments().then(({ results }) => {
      this.setState({
        segments: results,
        isLoading: false
      });
    }).catch(error => {
      setError(error ?? 'Error loading segments, please try again.');
    });

    listErgonars().then(({ results }) => {
      this.setState({
        ergonars: results,
        isLoading: false
      });
    }).catch(error => {
      setError(error ?? 'Error loading ergonars, please try again.');
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    this.setState({ formError: false });
  };

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  hasValidForm = () => {
    // These fields must be provided every time.
    if (this.state.code == '' || this.state.maxUseCount == '') {
      setError('Error: You must include the coupon code, and max use count.');
      return false;
    }

    if (this.state.ergonarId === 0 && this.state.use == 'ERGONAR') {
      setError('Error: You must select an ergonar.');
      return false;
    }

    if (this.state.segmentId === 0 && this.state.use != 'ERGONAR') {
      setError('Error: You must select a segment.');
      return false;
    }

    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const validForm = this.hasValidForm();

    this.setState({
      formError: !validForm,
      isLoading: true
    });

    if (!validForm) {
      this.setState({ isLoading: false });
      return;
    }

    var form = Object.assign({}, this.state);

    if (form.segmentId == 0 || form.use == 'ERGONAR') {
      form.segmentId = null;
    }

    if (form.ergonarId == 0 || form.use == 'TRAINING') {
      form.ergonarId = null;
    }

    if (form.type == 'PERCENT_OFF') {
      form.amountOff = '';
      form.percentOff = form.percentOff / 100;
    }

    if (form.type == 'AMOUNT_OFF') {
      form.percentOff = '';
    }


    form.code = form.code.toUpperCase();

    if (this.props.coupon == null) {
      createCoupon(form).then((coupon) => {
        this.setState({ isLoading: false });
        setSuccess('Successfully created coupon!');
        this.props.onSuccess(coupon);
      }).catch((error) => {
        setError(error ?? 'Error: Unable to complete request, please try again.');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateCoupon(form).then((coupon) => {
        this.setState({ isLoading: false });
        setSuccess('Successfully updated coupon!');
        this.props.onSuccess(coupon);
      }).catch((error) => {
        setError(error ?? 'Error: Unable to complete request, please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  formChanged() {
    return !(this.props.coupon?.getCode() === this.state.code &&
      this.props.coupon?.getUse() === this.state.use &&
      ((this.props.coupon?.getAmountOff() === null && this.state.amountOff === '') || (this.props.coupon?.getAmountOff() == this.state.amountOff)) &&
      ((this.props.coupon?.getPercentOff() === null && this.state.percentOff === '') || (this.props.coupon?.getPercentOff() == this.state.percentOff)) &&
      ((this.props.coupon?.getSegment()?.getId() === this.state.segmentId) || (this.props.coupon?.getSegment()?.getId() == null && this.state.segmentId === 0)) &&
      ((this.props.coupon?.getErgonar()?.getId() === this.state.ergonarId) || (this.props.coupon?.getErgonar()?.getId() == null && this.state.ergonarId === 0)) &&
      this.props.coupon?.getMaxUseCount() == this.state.maxUseCount &&
      this.props.coupon?.getIsActive() === this.state.isActive);
  }

  render() {
    return this.state.segments.length == 0 ? (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </div>
    ) : (
      <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>

        <TextField
          required
          error={this.state.formError && this.state.code === ''}
          name="code"
          label="Coupon Code"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.code}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          inputProps={{ style: { textTransform: 'uppercase' }, maxLength: 20, minLength: 5 }}
        />

        <TextField
          required
          select
          error={this.state.formError && this.state.use === ''}
          name="use"
          label="Coupon Use"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.use}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        >
          {COUPON_USES.map((use, i) => (
            <MenuItem key={i} value={use.enum}>
              {use.display}
            </MenuItem>
          ))}
        </TextField>

        {(this.state.use == 'ERGONAR') && <TextField
          select
          error={this.state.formError}
          required
          disabled={this.state.isLoading}
          name="ergonarId"
          label="Ergonar"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.ergonarId}
          onChange={this.onChange}
          variant='filled'
          defaultValue={this.props.coupon?.getErgonar()?.getId() || 0}
        >
          <MenuItem value={0}>None</MenuItem>
          {this.state.ergonars?.map((ergonar) => (
            <MenuItem key={ergonar.id} value={ergonar.id}>{ergonar.getName()}</MenuItem>
          ))}
        </TextField>}

        {(this.state.use == 'TRAINING') && <TextField
          select
          required
          error={this.state.formError}
          disabled={this.state.isLoading}
          name="segmentId"
          label="ErgoAlgo™ Segment"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.segmentId}
          onChange={this.onChange}
          variant='filled'
          defaultValue={this.props.coupon?.getSegment()?.getId() || 0}
        >
          <MenuItem value={0}>None</MenuItem>
          {this.state.segments?.map((segment) => (
            <MenuItem key={segment.id} value={segment.id}>{segment.getName()}</MenuItem>
          ))}
        </TextField>}

        <TextField
          required
          select
          error={this.state.formError && this.state.use === ''}
          name="type"
          label="Coupon Type"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.type}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        >
          {COUPON_TYPES.map((type, i) => (
            <MenuItem key={i} value={type.enum}>
              {type.display}
            </MenuItem>
          ))}
        </TextField>

        {(this.state.type == 'AMOUNT_OFF') && <TextField
          error={(this.state.formError && this.state.percentOff === '' && this.state.amountOff === '') || (this.state.formError && this.state.percentOff !== '' && this.state.amountOff !== '')}
          name="amountOff"
          label="Amount Off ($)"
          type='number'
          required
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.amountOff}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          inputProps={{ min: 1, max: MAX_INTEGER }}
        />}

        {(this.state.type == 'PERCENT_OFF') && <TextField
          error={(this.state.formError && this.state.percentOff === '' && this.state.amountOff === '') || (this.state.formError && this.state.percentOff !== '' && this.state.amountOff !== '')}
          name="percentOff"
          label="Percent Off (%)"
          type='number'
          required
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.percentOff}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          inputProps={{ min: 0, max: 100, step: 1 }}
        />}

        <TextField
          required
          name="maxUseCount"
          label="Maximum Number of Uses"
          type='number'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.maxUseCount}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
          inputProps={{ min: 1, max: MAX_INTEGER }}
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={this.state.isLoading}
              checked={this.state.isActive}
              onChange={this.onCheckboxChange}
              name="isActive"
            />
          }
          label="Set Active"
        />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
          {this.props.onClose != null &&
            <Button secondary disabled={this.state.isLoading} style={{ width: '100%', marginRight: '10px' }} onClick={this.props.onClose}>
              Cancel
            </Button>}

          <Button type='submit' isLoading={this.state.isLoading} disabled={this.state.error || (this.props.coupon != null && !this.formChanged())} style={{ width: '100%' }}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

CouponForm.propTypes = {
  coupon: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};

export default CouponForm;
