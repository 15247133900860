import {
  IS_LOADING,
  IS_NOT_LOADING,
  SOFT_AUTHENTICATED,
  FULLY_AUTHENTICATED,
  NOT_AUTHENTICATED
} from '../actions/authActions';

const initialState = {
  isLoading: true,
  isSoftAuthenticated: false,
  isFullyAuthenticated: false,
  twoFactorAuthSetupRequired: false,
  isAdmin: false
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case IS_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case IS_NOT_LOADING:
      return {
        ...state,
        isLoading: false
      };
    case SOFT_AUTHENTICATED:
      return {
        isLoading: false,
        isSoftAuthenticated: true,
        isFullyAuthenticated: false,
        twoFactorAuthSetupRequired: action.twoFactorAuthSetupRequired,
        isAdmin: action.isAdmin
      };
    case FULLY_AUTHENTICATED:
      return {
        ...state,
        isLoading: false,
        isSoftAuthenticated: true,
        isFullyAuthenticated: true,
        twoFactorAuthSetupRequired: false,
        isAdmin: action.isAdmin
      };
    case NOT_AUTHENTICATED:
      return {
        isAdmin: false,
        isLoading: false,
        isSoftAuthenticated: false,
        isFullyAuthenticated: false,
        twoFactorAuthSetupRequired: false
      };
    default:
      return state;
  }
};

export default auth;
