import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';

// Icons
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function BackToButton({ to, description = null, color = '#696969' }) {
  return (
    <Link to={to}>
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 40 }}>
        <div style={{ width: 18, height: 18, backgroundColor: color, borderRadius: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: 5 }}>
          <ChevronLeftIcon style={{ color: 'white', fontSize: 15 }} />
        </div>
        <Typography variant='overline' style={{ color: color }}>Back{description != null ? (' to ' + description) : ''}</Typography>
      </div>
    </Link>
  );
}
BackToButton.propTypes = {
  to: PropTypes.string.isRequired,
  description: PropTypes.string,
  color: PropTypes.string
};

export default BackToButton;
