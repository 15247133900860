import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MaterialTable } from '@lexcelon/react-util';
import { Link } from 'react-router-dom';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// ICONS
import VisibilityIcon from '@mui/icons-material/Visibility';
import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

const COLUMNS = [
  {
    title: 'View',
    value: rowData => rowData.getId(),
    render: rowData => (
      <Link to={'/evaluations/' + rowData.getId()}><VisibilityIcon /></Link>
    ),
  },
  {
    title: 'Evaluator',
    value: rowData => rowData.getEvaluator()?.getFullName(),
    render: rowData =>
      <Link to={'/people/' + rowData?.getEvaluator()?.getId()}>{rowData?.getEvaluator()?.getFullName()}</Link>,
    customFilterAndSearch: (term, rowData) => rowData?.getEvaluator()?.getFullName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getEvaluator()?.getFullName()?.localeCompare(b.getEvaluator()?.getFullName()),
  },
  {
    title: 'Evaluee',
    value: rowData => rowData.getEvaluee()?.getFullName(),
    render: rowData =>
      <Link to={'/people/' + rowData?.getEvaluee()?.getId()}>{rowData?.getEvaluee()?.getFullName()}</Link>,
    customFilterAndSearch: (term, rowData) => rowData?.getEvaluee()?.getFullName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getEvaluee()?.getFullName()?.localeCompare(b.getEvaluee()?.getFullName()),
  },
  {
    title: 'Segment',
    value: rowData => rowData.getSegment()?.getName(),
    render: rowData => rowData?.getSegment()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getSegment()?.getName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getSegment()?.getName()?.localeCompare(b.getSegment()?.getName()),
  },
  {
    title: 'Status',
    value: rowData => {
      switch (rowData?.getStatus()) {
        case 'SCHEDULED':
          return 'Waiting for Evaluee to Accept Invitation (Emailed)';
        case 'ACCEPTED':
          return 'Ready for Evaluation';
        default:
          return enumToDisplayString(rowData?.getStatus());
      }
    },
    customFilterAndSearch: (term, rowData) => rowData?.getStatus()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getStatus()?.localeCompare(b.getStatus()),
  },
  {
    title: 'Scheduled Date/Time',
    value: rowData => rowData?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME),
    customFilterAndSearch: (term, rowData) => rowData?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME).toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getScheduledDateTime() - b.getScheduledDateTime(),
  },
  {
    title: 'Location',
    value: rowData => rowData?.getAddress() ? (rowData?.getAddress()?.getCity() + ', ' + rowData?.getAddress()?.getState()) : null,
    customFilterAndSearch: (term, rowData) => (rowData?.getAddress()?.getCity()?.toLowerCase() + ', ' + rowData?.getAddress()?.getState()?.toLowerCase())?.includes(term?.toLowerCase())
  },
  {
    title: 'Group',
    value: rowData => rowData?.getGroup()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getGroup()?.getName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getGroup()?.getName()?.localeCompare(b.getGroup()?.getName()),
  },
];

class EvaluationsTable extends Component {
  render() {
    return (
      <div style={{ margin: '2em' }}>
        <MaterialTable
          title={this.props.title ? this.props.title : ''}
          data={{
            mode: 'list',
            rows: this.props.evaluations,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20,
          }}
          isLoading={this.props.isLoading}
        />
      </div>
    );
  }
}

EvaluationsTable.propTypes = {
  evaluations: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  title: PropTypes.any
};

export default EvaluationsTable;
