import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';

// Components
import { BackToButton, EvaluationsTable } from '../../../../../components';

// API
import { listEvalueeEvaluations, getPerson } from '../../../../../api';

// Alerts
import { setError } from '../../../../../alerts';

class EvalueeEvaluations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evaluations: [],
      isLoading: false
    };
  }

  componentDidMount() {
    const personId = this.props.match?.params?.id;
    getPerson(personId).then(person => {
      if (person != null) {
        this.setState({ person: person });
      }
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve person.');
    });

    this.setState({ isLoading: true });
    listEvalueeEvaluations({ personId }).then(({ results }) => {
      if (results != null) {
        this.setState({ evaluations: results });
      }
      this.setState({ isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve Evaluations.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/people/' + this.state.person?.getId()} description={this.state.person?.getFullName()} />
        </div>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Evaluations for {this.state.person?.getFullName()}</Typography>
        <EvaluationsTable evaluations={this.state.evaluations} isLoading={this.state.isLoading} />
      </>
    );
  }
}

EvalueeEvaluations.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(EvalueeEvaluations);
