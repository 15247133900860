import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Alerts
import { setError, setSuccess } from '../../../alerts';

// API
import { getEvaluation, deleteEvaluation, resendEvalueeInvitation, regenerateReport } from '../../../api';

// Components
import { EvaluationForm } from '../../../components';
import { Button, LineItem } from '@lexcelon/react-util';
import { PDFViewer } from '@ergonauts/ergo-algo-react/core/components';
import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

class Evaluation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evaluation: null,
      editMode: false,
      redirect: false,
      isResendLoading: false,
      isCancelLoading: false,
      isRegenerateLoading: false
    };
  }

  componentDidMount() {
    this.loadEvaluation();
  }

  loadEvaluation() {
    const evaluationId = this.props.match?.params?.id;
    getEvaluation(evaluationId).then((evaluation) => {
      this.setState({
        evaluation: evaluation
      });
    }).catch((error) => {
      setError(error ?? 'Unable to load that requested Evaluation.');

      this.setState({
        redirect: true,
      });
    });
  }

  resendEvalueeInvitation() {
    this.setState({ isResendLoading: true });
    resendEvalueeInvitation(this.state.evaluation?.id).then(() => {
      setSuccess('Successfully resent invitation');
      this.setState({ isResendLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: could not resend invitation');
      this.setState({ isResendLoading: false });
    });
  }

  deleteEvaluation() {
    this.setState({ isCancelLoading: true });
    deleteEvaluation(this.state.evaluation?.id).then(() => {
      setSuccess('Successfully cancelled evaluation');
      this.setState({ isCancelLoading: false });
      this.loadEvaluation();
    }).catch(error => {
      setError(error ?? 'Could not cancel evaluation');
      this.setState({ isCancelLoading: false });
    });
  }

  onEditSuccess = () => {
    this.setState({
      editMode: false
    });

    this.loadEvaluation();
  }

  regenerateReport() {
    this.setState( { isRegenerateLoading: true });
    regenerateReport(this.state.evaluation?.id).then(() => {
      setSuccess('Successfully requested report regeneration. Please wait a few minutes.');
      this.setState({ isRegenerateLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: could not regenerate report');
      this.setState({ isRegenerateLoading: false });
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/evaluations'} />;
    }

    if (this.state.editMode) {
      return (
        <Container>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Edit Evaluation</Typography>
          <EvaluationForm
            onSuccess={this.onEditSuccess}
            evaluation={this.state.evaluation}
            onClose={() => this.setState({ editMode: false })}
          />
        </Container>
      );
    }

    return (
      <Container style={{ marginBottom: 50 }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.evaluation?.getSegment()?.getName()} Evaluation</Typography>

        {/* Overview */}
        <>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Overview</Typography>

          <LineItem
            value={
              (() => {
                switch (this.state.evaluation?.getStatus()) {
                  case 'SCHEDULED':
                    return 'Waiting for Evaluee to Accept Invitation (Emailed)';
                  case 'ACCEPTED':
                    return 'Ready for Evaluation';
                  default:
                    return enumToDisplayString(this.state.evaluation?.getStatus());
                }
              })()
            }
            description='Status'
          />
          <LineItem
            value={this.state.evaluation?.getScheduledDateTime()?.toLocaleString(FULL_DATE_TIME)}
            description='Date/Time'
          />
          <LineItem
            value={this.state.evaluation?.getAddress()?.getFullAddress()}
            description='Evaluation Address'
          />
          <LineItem
            value={<Link to={'/groups/' + this.state.evaluation?.getGroup()?.getId()}>{this.state.evaluation?.getGroup()?.getName()}</Link>}
            description='Group'
          />
          <LineItem
            value={this.state.evaluation?.getIsPaid() ? 'Paid' : 'Unpaid'}
            description='Payment Status'
          />
        </>

        {/* Evaluee Info */}
        <>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Evaluee</Typography>

          <LineItem
            value={<Link to={'/people/' + this.state.evaluation?.getEvaluee()?.getId()}>{this.state.evaluation?.getEvaluee()?.getFullName()}</Link>}
            description='Evaluee'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluee()?.getEmail()}
            description='Evaluee Email'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluee()?.getPhoneNumber()}
            description='Evaluee Phone Number'
          />
          <LineItem
            value={this.state.evaluation?.getEvalueeCanViewReport() ? 'Yes' : 'No'}
            description='Evaluee Can View Report'
          />
        </>

        {/* Evaluator Info */}
        <>
          <Typography variant='h2' style={{ marginTop: '1em' }}>Evaluator</Typography>

          <LineItem
            value={<Link to={'/people/' + this.state.evaluation?.getEvaluator()?.getId()}>{this.state.evaluation?.getEvaluator()?.getFullName()}</Link>}
            description='Evaluator'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluator()?.getEmail()}
            description='Evaluator Email'
          />
          <LineItem
            value={this.state.evaluation?.getEvaluator()?.getPhoneNumber()}
            description='Evaluator Phone Number'
          />
        </>

        {/* Report */}
        {this.state.evaluation?.getReportUrl() != null &&
        <PDFViewer style={{ marginTop: 30, marginBottom: 30 }} url={this.state.evaluation.getReportUrl()} />}

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {(this.state.evaluation?.getStatus() == 'ACCEPTED' || this.state.evaluation?.getStatus() == 'SCHEDULED') &&
            <Button
              danger
              isLoading={this.state.isCancelLoading}
              disabled={this.state.isResendLoading}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.deleteEvaluation();
              }}
            >
              Cancel Evaluation
            </Button>
          }

          {(this.state.evaluation?.getStatus() != 'COMPLETED' && this.state.evaluation?.getStatus() != 'CANCELLED' && this.state.evaluation?.getStatus() != 'REJECTED') &&
            <Button
              disabled={this.state.isCancelLoading || this.state.isResendLoading || this.state.isRegenerateLoading}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.setState({
                  editMode: true
                });
              }}
              secondary
            >
              Edit Evaluation
            </Button>
          }

          {(this.state.evaluation?.getStatus() == 'SCHEDULED') &&
            <Button
              isLoading={this.state.isResendLoading}
              disabled={this.state.isCancelLoading}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.resendEvalueeInvitation();
              }}
              secondary
            >
              Resend Evaluee Invitation
            </Button>
          }

          {(this.state.evaluation?.getStatus() == 'COMPLETED') &&
            <Button
              secondary
              isLoading={this.state.isRegenerateLoading}
              disabled={this.state.isCancelLoading}
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.regenerateReport();
              }}
            >
              Regenerate Report
            </Button>
          }
        </div>
      </Container>
    );
  }
}

Evaluation.propTypes = {
  match: PropTypes.any
};

export default withRouter(Evaluation);
