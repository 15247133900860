/*
* Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
* Licensed for non-distributable use to Ergonauts LLC
*/
import React from 'react';
import PropTypes from 'prop-types';

import { LineItem } from '@lexcelon/react-util';

function EvaluatorInfoSection({ evaluator }){
  return (
    <>
      <div>

        <div style={{ display: 'flex', justifyContent: 'left', marginTop: '0.5em', marginBottom: '0.3em' }}>
          <img src={evaluator?.getIndependentLogoUrl()} style={{ width: '150px', height: 'auto' }} />
        </div>

        <LineItem
          value={evaluator?.getDoingBusinessAsName()}
          description='DBA (Doing Business As) Name'
        />

        <LineItem
          value={evaluator?.getWorkAddress() ? (evaluator?.getWorkAddress()?.getLine1() + ' ' + (evaluator?.getWorkAddress()?.getLine2() ? evaluator?.getWorkAddress()?.getLine2() : '') + ' ' + evaluator?.getWorkAddress()?.getCity() + ', ' + evaluator?.getWorkAddress()?.getState() + ', ' + evaluator?.getWorkAddress()?.getZip()) : null}
          description='Work Address'
        />

        <LineItem
          value={evaluator?.getRangeOfTravel()}
          description='Range of Travel'
        />

        <LineItem
          value={evaluator?.getReceiveReferrals() ? 'Yes' : 'No'}
          description='Receiving Referrals'
        />

        <LineItem
          value={evaluator?.getPercentRetainedForIndependentEvaluation()}
          description='% Retained For Independent Evaluation'
        />

        <LineItem
          value={evaluator?.getNotes()}
          description='Notes'
        />

      </div>
    </>
  );
}

EvaluatorInfoSection.propTypes = {
  evaluator: PropTypes.object,
};

export default EvaluatorInfoSection;
