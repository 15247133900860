import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';

// Components
import { Button, MaterialTable } from '@lexcelon/react-util';

// Icons
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

// API
import { listCoupons } from '../../api';

// Alerts
import { setError } from '../../alerts';

import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

const COLUMNS = [
  {
    title: 'View',
    value: rowData => rowData.getId(),
    render: rowData => (
      <Link to={'/coupons/' + rowData.getId()}><VisibilityIcon /></Link>
    ),
  },
  {
    value: rowData => rowData.getCode(),
    title: 'Code'
  },
  {
    title: 'Use',
    value: rowData => enumToDisplayString(rowData?.getUse())
  },
  {
    value: rowData => rowData.getCurrentUseCount(),
    title: 'Current Use Count',
    customFilterAndSearch: (term, rowData) => rowData.getCurrentUseCount() != null && rowData.getCurrentUseCount() == term
  },
  {
    value: rowData => rowData.getMaxUseCount(),
    title: 'Max Use Count',
    customFilterAndSearch: (term, rowData) => rowData.getMaxUseCount() != null && rowData.getMaxUseCount() == term
  },
  {
    title: 'Percent off',
    customFilterAndSearch: (term, rowData) => rowData.getPercentOff() != null && rowData.getPercentOff()*100 == term,
    value: rowData => rowData.getPercentOff() && rowData?.getPercentOff()*100 + '%'
  },
  {
    title: 'Amount Off',
    customFilterAndSearch: (term, rowData) => rowData.getAmountOff() != null && rowData.getAmountOff() == term,
    value: rowData => rowData.getAmountOff() && '$' + rowData?.getAmountOff()
  },
  {
    value: rowData => rowData.getIsActive() ? 'Active' : 'Inactive',
    title: 'Is Active'
  },
  {
    title: 'Segment',
    customFilterAndSearch: (term, rowData) => rowData?.getSegment()?.getName()?.toLowerCase()?.includes(term?.toLowerCase()),
    value: rowData => rowData?.getSegment()?.getName()
  },
  {
    title: 'Ergonar',
    value: rowData => rowData.getErgonar()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getErgonar()?.getName()?.toLowerCase()?.includes(term?.toLowerCase()),
    render: rowData => rowData.getErgonar() && (
      <a href={'/training/ergonars/' + rowData?.getErgonar()?.getId()}>{rowData?.getErgonar()?.getName()}</a>
    )
  }
];

class Coupon extends Component {
  constructor(props) {
    super(props);

    this.state = {
      coupons: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listCoupons().then(({ results }) => {
      this.setState({ coupons: results, isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve coupons.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Coupons</Typography>
        <MaterialTable
          title={
            <Button component={Link} to='/coupons/create' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
              Create New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'list',
            rows: this.state.coupons,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

export default Coupon;
