import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Header } from '@lexcelon/react-util';
import { WhiteLogo } from '@ergonauts/ergo-algo-react/core/images';
import { DARK_BLUE, TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';

// Redux
import { connect } from 'react-redux';
import { checkAuthentication, logoutPerson } from '../redux/actions/authActions';

const LOGGED_OUT_LINKS = [
  {
    slug: '/auth/login',
    text: 'Login'
  }
];

const LOGGED_IN_LINKS = [
  {
    slug: '/people',
    text: 'People'
  },
  {
    slug: '/groups',
    text: 'Groups'
  },
  {
    slug: '/evaluations',
    text: 'Evaluations'
  },
  {
    slug: '/certifications',
    text: 'Evaluator Certifications'
  },
  // null,
  // {
  //   slug: '/background-checks',
  //   text: 'Evaluator Background Checks'
  // },
  null,
  {
    slug: '/training',
    text: 'Evaluator Training'
  },
  {
    slug: '/training/ergonars',
    text: 'Ergonars'
  },
  {
    slug: '/training/training-modules',
    text: 'Training Modules'
  },
  {
    slug: '/coupons',
    text: 'Coupons'
  },
  null,
  {
    slug: '/portal',
    text: 'Back to Portal'
  }
];

class AppBar extends Component {
  componentDidMount() {
    this.props.checkAuthentication(this.props.isSoftAuthenticated, this.props.isFullyAuthenticated);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isSoftAuthenticated !== this.props.isSoftAuthenticated || prevProps.isFullyAuthenticated !== this.props.isFullyAuthenticated) {
      this.props.checkAuthentication(this.props.isSoftAuthenticated, this.props.isFullyAuthenticated);
    }
  }

  render() {
    return (
      <Header
        drawer={this.props.isFullyAuthenticated}
        logo={WhiteLogo}
        logoDescription='Ergonauts™ Logo'
        links={this.props.isFullyAuthenticated ? LOGGED_IN_LINKS : LOGGED_OUT_LINKS}
        backgroundColor={DARK_BLUE}
        textColor={TEXT_WHITE}
        onLogout={this.props.isFullyAuthenticated ? this.props.logoutPerson : null}
        isLoading={this.props.isLoading}
      />
    );
  }
}

AppBar.propTypes = {
  checkAuthentication: PropTypes.func.isRequired,
  logoutPerson: PropTypes.func.isRequired,
  isSoftAuthenticated: PropTypes.bool.isRequired,
  isFullyAuthenticated: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  isSoftAuthenticated: state.auth.isSoftAuthenticated,
  isFullyAuthenticated: state.auth.isFullyAuthenticated,
  isLoading: state.auth.isLoading
});

const mapDispatchToProps = dispatch => ({
  checkAuthentication: (personId, isAdmin) => checkAuthentication(dispatch, personId, isAdmin),
  logoutPerson: () => logoutPerson(dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(AppBar);
