import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography, TextField, MenuItem } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import AddressInput from './AddressInput';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Constants
import { GENDER_OPTIONS } from '@ergonauts/ergo-algo-react/core/constants';

class ProfileForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
      },
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
      gender: '',
      isLoading: false
    };
  }

  componentDidMount() {
    if (this.props.person != null) {
      this.setState({
        address: {
          line1: this.props.person.getMailingAddress()?.getLine1() ?? '',
          line2: this.props.person.getMailingAddress()?.getLine2() ?? '',
          city: this.props.person.getMailingAddress()?.getCity() ?? '',
          state: this.props.person.getMailingAddress()?.getState() ?? '',
          zip: this.props.person.getMailingAddress()?.getZip() ?? ''
        },
        firstName: this.props.person.getFirstName() ?? '',
        lastName: this.props.person.getLastName() ?? '',
        phoneNumber: this.props.person.getPhoneNumber() ?? '',
        email: this.props.person.getEmail() ?? '',
        gender: this.props.person.getGender() ?? ''
      });
    }

    if (window && window.google) {
      var address1Field = document.querySelector('#line1');
      this.setState({
        autocomplete: new window.google.maps.places.Autocomplete(address1Field, {
          componentRestrictions: { country: ['us'] },
          fields: ['address_components'],
          types: ['address'],
        })
      }, () => {
        this.state.autocomplete.addListener('place_changed', this.fillInAddress);
      });
    }

    this.fillInAddress = this.fillInAddress.bind(this);
  }

  fillInAddress() {
    if (this.state.autocomplete) {
      // Get the place details from the autocomplete object.
      const place = this.state.autocomplete.getPlace();
      let address1 = '';
      var city = '';
      var state = '';
      var zip = '';

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address1 = `${component.long_name} ${address1}`;
            break;
          }
          case 'route': {
            address1 += component.short_name;
            break;
          }
          case 'postal_code':
            zip = component.long_name;
            break;
          case 'locality':
            city = component.long_name;
            break;
          case 'administrative_area_level_1': {
            state = component.short_name;
            break;
          }
          default:
        }
      }

      this.setState({ address: { line1: address1, city: city, state: state, zip: zip } });

      // After filling the form with address components from the Autocomplete
      // prediction, set cursor focus on the second address line to encourage
      // entry of subpremise information such as apartment, unit, or floor number.
      var address2Field = document.querySelector('#line2');
      address2Field.focus();
    }
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let {
      address,
      firstName,
      lastName,
      phoneNumber,
      email,
      gender
    } = this.state;

    this.props.onSubmit({
      firstName,
      lastName,
      phoneNumber,
      email,
      gender,
      line1: address?.line1,
      city: address?.city,
      state: address?.state,
      zip: address?.zip
    });
  };

  formChanged() {
    return !(this.props.person.getFirstName() === this.state.firstName &&
    this.props.person.getLastName() === this.state.lastName &&
    this.props.person.getPhoneNumber() === this.state.phoneNumber &&
    this.props.person.getEmail() === this.state.email &&
    this.props.person.getGender() === this.state.gender &&
    this.props.person.getMailingAddress()?.getLine1() === this.state.address.line1 &&
    ((this.props.person.getMailingAddress()?.getLine2() === null && this.state.address.line2 === '') || (this.props.person.getMailingAddress()?.getLine2() == this.state.address.line2)) &&
    this.props.person.getMailingAddress()?.getCity() === this.state.address.city &&
    this.props.person.getMailingAddress()?.getState() === this.state.address.state &&
    this.props.person.getMailingAddress()?.getZip() == this.state.address.zip);
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
        {/* ----- PERSONAL INFORMATION ----- */}
        <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Personal Information</Typography>

        {/* Name Input */}
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '10px' }}>
          <TextField
            required
            disabled={this.state.isLoading}
            error={this.state.formError && this.state.firstName === ''}
            name="firstName"
            label="First Name"
            style={{ width: '49%' }}
            value={this.state.firstName}
            onChange={this.onChange}
            variant='filled'
          />
          <TextField
            required
            disabled={this.state.isLoading}
            error={this.state.formError && this.state.lastName === ''}
            name="lastName"
            label="Last Name"
            style={{ width: '49%' }}
            value={this.state.lastName}
            onChange={this.onChange}
            variant='filled'
          />
        </div>

        {/* Email */}
        <TextField
          required
          disabled={this.state.isLoading}
          name="email"
          label="Email"
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.email}
          onChange={this.onChange}
          variant='filled'
          type='email'
        />

        {/* Phone Number */}
        <ReactPhoneInput
          country='us'
          onlyCountries={['us']}
          value={this.state.phoneNumber}
          onChange={(phoneNumber) => this.setState({ phoneNumber })}
          component={TextField}
          inputProps={{ variant: 'filled', required: true, label: 'Phone Number', disabled: this.state.isLoading }}
          disableCountryCode={true}
          disableDropdown={true}
          placeholder='(702) 123-4567'
          containerStyle={{ width: '100%', marginBottom: '10px' }}
        />

        {/* Gender */}
        <TextField
          required
          select
          disabled={this.state.isLoading}
          error={this.state.formError && this.state.gender === ''}
          name="gender"
          label="Gender"
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.gender}
          onChange={this.onChange}
          variant='filled'>
          {GENDER_OPTIONS.map((gender, i) => (
            <MenuItem key={i} value={gender.enum}>
              {gender.display}
            </MenuItem>
          ))}
        </TextField>

        {/* ----- MAILING ADDRESS ----- */}
        <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '20px' }}>Mailing Address</Typography>

        <AddressInput
          isLoading={this.state.isLoading}
          onChange={(address) => this.setState({ address })}
          requiredFields={['line1', 'city', 'state', 'zip']}
          initialValue={this.props.person != null ? {
            line1: this.props.person.getMailingAddress()?.getLine1() ?? '',
            line2: this.props.person.getMailingAddress()?.getLine2() ?? '',
            city: this.props.person.getMailingAddress()?.getCity() ?? '',
            state: this.props.person.getMailingAddress()?.getState() ?? '',
            zip: this.props.person.getMailingAddress()?.getZip() ?? ''
          } : undefined}
        />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
          <Button secondary disabled={this.state.isLoading} onClick={this.props.closeForm} style={{ width: '49%' }}>
            Cancel
          </Button>
          <Button type='submit' isLoading={this.state.isLoading} disabled={this.props.person != null && !this.formChanged()} style={{ width: '49%' }}>
            Update Settings
          </Button>
        </div>
      </form>
    );
  }
}

ProfileForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  line1: PropTypes.string,
  line2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  phoneNumber: PropTypes.string,
  gender: PropTypes.string,
  closeForm: PropTypes.func,
  person: PropTypes.object.isRequired
};

export default ProfileForm;
