import React from 'react';
import {
  Typography,
  Card
} from '@mui/material';
import PropTypes from 'prop-types';

function EmergencyContactCard({ name, phoneNumber, email, relation }) {
  return (
    <Card style={{ margin: '1em', padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: (window.innerWidth < 700 ? 'auto' : '40%') }}>
      <Typography variant='h5' style={{ textAlign: 'center' }}>{name}</Typography>
      <Typography variant='body1'>{phoneNumber}</Typography>
      <Typography variant='body1'>{email}</Typography>
      <Typography variant='body1'>{relation}</Typography>
    </Card>
  );
}

EmergencyContactCard.propTypes = {
  name: PropTypes.string,
  phoneNumber: PropTypes.string,
  email: PropTypes.string,
  relation: PropTypes.string
};

export default EmergencyContactCard;