import PropTypes from 'prop-types';
import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import AuthRedirect from './AuthRedirect';

// Redux
import { connect } from 'react-redux';

class PageWrapper extends Component {
  render() {
    return AuthRedirect(this.props.location.pathname, this.props.authProps) || this.props.children;
  }
}

PageWrapper.propTypes = {
  authProps: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.any
};

const mapStateToProps = state => ({
  authProps: state.auth
});

export default connect(mapStateToProps, null)(withRouter(PageWrapper));
