import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';
import { Typography } from '@mui/material';

// API
import { listGroupInvitationAssociations, getGroup, resendGroupInvitation, revokeGroupInvitation } from '../../../../api';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

// Components
import { Button, MaterialTable } from '@lexcelon/react-util';
import { BackToButton } from '../../../../components';

// Icons
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EmailIcon from '@mui/icons-material/Email';

const COLUMNS = ({ isLoading, loadingId, loadingAction, onRevokeInvitation, onResendInvitation }) => ([
  {
    title: 'Email',
    value: rowData => rowData?.getPerson()?.getEmail()
  },
  { // allows evaluees
    title: 'Role',
    value: rowData => rowData?.getRole()?.getRole(),
  },
  {
    title: 'Sent On',
    value: rowData => rowData?.getCreatedAt()?.toLocaleString(FULL_DATE_TIME)
  },
  {
    title: 'Expires On',
    value: rowData => rowData?.getExpiresOn()?.toLocaleString(FULL_DATE_TIME)
  },
  {
    title: 'Resend',
    value: () => null,
    render: rowData =>
      <Button
        isLoading={isLoading && loadingId == rowData?.id && loadingAction == 'resend'}
        disabled={isLoading}
        onClick={() => onResendInvitation(rowData?.groupId, rowData?.id)}>
        <EmailIcon />
      </Button>
  },
  {
    title: 'Delete',
    value: () => null,
    render: rowData =>
      <Button
        isLoading={isLoading && loadingId == rowData?.id && loadingAction == 'revoke'}
        disabled={isLoading}
        onClick={() => onRevokeInvitation(rowData?.groupId, rowData?.id)}
      >
        <DeleteIcon />
      </Button>
  },
]);

class GroupInvitations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invitations: [],
      group: null,
      isLoading: false,
      loadingId: 0,
      loadingAction: '',
      isInvitationsLoading: false,
    };
  }

  componentDidMount() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      this.setState({ group: group });
    }).catch(error => {
      setError(error ?? 'Error: Could not load group.');
    });
    this.loadInvitations(groupId);
  }

  resendInvitation = (groupId, groupAssociationId) => {
    this.setState({
      isLoading: true,
      loadingId: groupAssociationId,
      loadingAction: 'resend',
    });
    resendGroupInvitation({ groupId, groupAssociationId }).then(() => {
      setSuccess('Successfully resent invitation');
      this.setState({ isLoading: false, loadingAction: '' });
      this.loadInvitations(groupId);
    }).catch(error => {
      setError(error ?? 'Error: Unable to resend invitation');
      this.setState({ isLoading: false });
    });
  }

  revokeInvitation = (groupId, groupAssociationId) => {
    this.setState({
      isLoading: true,
      loadingId: groupAssociationId,
      loadingAction: 'revoke',
    });
    revokeGroupInvitation({ groupId, groupAssociationId }).then(() => {
      setSuccess('Successfully revoked invitation');
      this.setState({ isLoading: false, loadingAction: '' });
      this.loadInvitations(groupId);
    }).catch(error => {
      setError(error ?? 'Unable to revoke invitation');
      this.setState({ isLoading: false });
    });
  }

  loadInvitations(groupId) {
    this.setState({ isInvitationsLoading: true });
    listGroupInvitationAssociations({ groupId }).then(({ results }) => {
      this.setState({ invitations: results, isInvitationsLoading: false });
    }).catch(error => {
      setError(error ?? 'Unable to retrieve invitations');
      this.setState({ isInvitationsLoading: false });
    });
  }

  render() {
    return (
      <>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/groups/' + this.state.group?.getId()} description={this.state.group?.getName()} />
        </div>
        <div style={{ margin: '2em' }}>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>{this.state.group?.name} Invitations</Typography>
          <MaterialTable
            title={
              <Button isLoading={this.state.isLoading && this.state.loadingAction == ''} disabled={this.state.isLoading} component={Link} to={'/groups/' + this.props.match?.params?.id + '/invitations/create'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
                Create New
                <AddIcon style={{ marginLeft: '5px' }} />
              </Button>
            }
            data={{
              mode: 'list',
              rows: this.state.invitations,
              columns: COLUMNS({ isLoading: this.state.isLoading, loadingId: this.state.loadingId, loadingAction: this.state.loadingAction, onRevokeInvitation: this.revokeInvitation, onResendInvitation: this.resendInvitation })
            }}
            options={{
              pageSize: 20
            }}
            isLoading={this.state.isInvitationsLoading}
          />
        </div>
      </>
    );
  }
}

GroupInvitations.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GroupInvitations);
