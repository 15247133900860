import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Container, Typography } from '@mui/material';

// Components
import { BackToButton, CouponForm } from '../../../components';

class CreateCoupon extends Component {

  constructor(props) {
    super(props);

    this.state = {
      segments: null,
      redirect: false,
      coupon: null
    };
  }

  onSuccess = (coupon) => {
    this.setState({ redirect: true, coupon: coupon });
  };

  render() {
    if (this.state.redirect && this.state.coupon != null) {
      return <Redirect to={'/coupons/' + this.state.coupon.id} />;
    }

    return (
      <Container>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/coupons'} description='Coupons' />
        </div>

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Create a Coupon</Typography>

        <CouponForm onSuccess={this.onSuccess} />
      </Container>
    );
  }
}

export default CreateCoupon;
