import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter, Redirect } from 'react-router-dom';
import { Container, Card, Typography, Divider, IconButton } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// Icons
import EditIcon from '@mui/icons-material/Edit';

// Alerts
import { setError, setSuccess } from '../../../alerts';

// Components
import { BackToButton, EvaluatorInfoSection, EvaluatorProfileForm, ProfileForm, ProfileInfoSection } from '../../../components';

// Constants
import { ERGO_BLUE, BLUE, TEXT_WHITE } from '@ergonauts/ergo-algo-react/core/constants';

// API
import { getPerson, updatePerson, updateEvaluator, suspendPerson } from '../../../api';


class Person extends Component {

  constructor(props) {
    super(props);

    this.state = {
      person: null,
      redirect: false,
      inEditProfileInfoMode: false,
      inEditEvaluatorInfoMode: false
    };
  }

  componentDidMount() {
    const personId = this.props.match?.params?.id;

    if (personId == null) {
      setError('Error: Could not retrieve Id.');
      this.setState({ redirect: true });
    }
    else {
      getPerson(personId).then((person) => {
        this.setState({ person: person });
      }).catch((error) => {
        setError(error ?? 'Error: Could not retrieve person.');
        this.setState({ redirect: true });
      });
    }
  }

  retrievePerson = () => {
    this.setState({ isLoading: true });
    getPerson(this.state.person?.getId()).then((person) => {
      this.setState({ person: person, isLoading: false });
    }).catch((error) => {
      this.setState({ isLoading: false });
      setError(error ?? 'Error: Could not retrieve person.');
    });
  }


  startEditProfileInfoMode = () => {
    this.setState({ inEditProfileInfoMode: true });
  }

  exitEditProfileInfoMode = () => {
    this.setState({ inEditProfileInfoMode: false });
  }

  startEditEvaluatorInfoMode = () => {
    this.setState({ inEditEvaluatorInfoMode: true });
  }

  exitEditEvaluatorInfoMode = () => {
    this.setState({ inEditEvaluatorInfoMode: false });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onUpdateProfileInfo = (info) => {
    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      gender,
      line1,
      city,
      state,
      zip
    } = info;

    // Replace empty strings with null
    let line2 = (info.line2 === '' ? null : info.line2);

    const address = { line1, line2, city, state, zip };

    this.setState({ isLoading: true });
    updatePerson({ id: this.state.person?.getId(), firstName, lastName, phoneNumber, email, gender, address }).then(() => {
      this.retrievePerson();
      this.setState({ isLoading: false });
      this.exitEditProfileInfoMode();
      setSuccess('Successfully updated person');
    }).catch(error => {
      this.setState({ isLoading: false });
      setError(error);
    });
  }

  onUpdateEvaluatorInfo = (info) => {
    const {
      doingBusinessAsName,
      line1,
      line2,
      city,
      state,
      zip,
      rangeOfTravel,
      receiveReferrals,
      notes,
      displayImage
    } = info;

    this.setState({ isLoading: true });
    updateEvaluator({ id: this.state.person?.getId(), rangeOfTravel, receiveReferrals, notes, line1, line2, city, state, zip, doingBusinessAsName, displayImage }).then(() => {
      this.retrievePerson();
      this.setState({ isLoading: false });
      this.exitEditEvaluatorInfoMode();
      setSuccess('Successfully updated evaluator');
    }).catch(error => {
      this.setState({ isLoading: false });
      setError(error);
    });
  }

  onSuspendAccount() {
    let suspended = !this.state.person?.getSuspended();

    this.setState({ isLoading: true });
    suspendPerson({ personId: this.state.person?.getId(), suspended }).then(() => {
      this.retrievePerson();
      this.setState({ isLoading: false });
      setSuccess('Successfully ' + (suspended ? 'suspended' : 'unsuspended') + ' account.');
    }).catch(error => {
      this.setState({ isLoading: false });
      setError(error);
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to='/people' />;
    }

    //NOTE: Remove test from the stripe URL below when we are in production
    return (
      <Container style={{ paddingTop: '20px' }}>
        <BackToButton to='/people' description='people' />
        <Typography variant='h2' style={{ fontWeight: 500, textAlign: 'center', marginTop: '30px' }}>{this.state.person?.getFirstName() + ' ' + this.state.person?.getLastName()}</Typography>

        {/* Profile Section */}
        <Card style={{ padding: '30px', margin: '30px', position: 'relative', background: (this.state.inEditProfileInfoMode ? 'white' : 'linear-gradient(180deg, ' + ERGO_BLUE + ' 0%, ' + BLUE + ' 100%)'), color: (this.state.inEditProfileInfoMode ? 'black' : TEXT_WHITE) }}>

          {/* Display Mode */}
          {!this.state.inEditProfileInfoMode &&
            <>
              <IconButton aria-label="delete" style={{ position: 'absolute', top: '2px', right: '2px' }} onClick={this.startEditProfileInfoMode}>
                <EditIcon style={{ color: TEXT_WHITE }} />
              </IconButton>
              <Typography variant='h2' style={{ textAlign: 'center' }}>Profile</Typography>
              <ProfileInfoSection person={this.state.person} />
            </>
          }

          {/* Edit Mode */}
          {this.state.inEditProfileInfoMode &&
            <div>
              <Typography variant='h2' style={{ textAlign: 'center' }}>Profile</Typography>
              <ProfileForm closeForm={this.exitEditProfileInfoMode} onSubmit={this.onUpdateProfileInfo} person={this.state.person} />
            </div>
          }
        </Card>

        {/* Evaluator Section */}
        {this.state.person?.getEvaluator() != null &&
          <Card style={{ padding: '30px', margin: '30px', position: 'relative', background: (this.state.inEditEvaluatorInfoMode ? 'white' : 'linear-gradient(180deg, ' + ERGO_BLUE + ' 0%, ' + BLUE + ' 100%)'), color: (this.state.inEditEvaluatorInfoMode ? 'black' : TEXT_WHITE) }}>

            {/* Display Mode */}
            {!this.state.inEditEvaluatorInfoMode &&
              <>
                <IconButton aria-label="delete" style={{ position: 'absolute', top: '2px', right: '2px' }} onClick={this.startEditEvaluatorInfoMode}>
                  <EditIcon style={{ color: TEXT_WHITE }} />
                </IconButton>
                <Typography variant='h2' style={{ textAlign: 'center' }}>Evaluator</Typography>
                <EvaluatorInfoSection evaluator={this.state.person?.getEvaluator()} />
              </>
            }

            {/* Edit Mode */}
            {this.state.inEditEvaluatorInfoMode &&
              <>
                <Typography variant='h2' style={{ textAlign: 'center' }}>Evaluator</Typography>
                <EvaluatorProfileForm onClose={this.exitEditEvaluatorInfoMode} onSubmit={this.onUpdateEvaluatorInfo} evaluator={this.state.person?.getEvaluator()} />
              </>
            }
          </Card>}

        {/* Emergency Contacts
        <Card style={{ padding: '30px', margin: '30px', position: 'relative', background: 'linear-gradient(180deg, ' + ERGO_BLUE + ' 0%, ' + BLUE + ' 100%)', color: TEXT_WHITE }}>
          <IconButton aria-label="delete" style={{ position: 'absolute', top: '2px', right: '2px' }}>
            <EditIcon style={{ color: TEXT_WHITE }} />
          </IconButton>
          <Typography variant='h2' style={{ textAlign: 'center', marginBottom: '20px' }}>Emergency Contacts</Typography>
          <div style={{ width: (window.innerWidth < 700 ? '100%' : '80%'), margin: 'auto', display: 'flex', justifyContent: 'center', flexDirection: (window.innerWidth < 700 ? 'column' : 'row') }}>
            {this.state.person?.getEmergencyContact1() &&
              <EmergencyContactCard
                name={this.state.person?.getEmergencyContact1()?.getFirstName() + ' ' + this.state.person?.getEmergencyContact1()?.getLastName()}
                phoneNumber={this.state.person?.getEmergencyContact1()?.getPhoneNumber()}
                email={this.state.person?.getEmergencyContact1()?.getEmail()}
                relation={this.state.person?.getEmergencyContact1()?.getRelationship()}
              />
            }
            {this.state.person?.getEmergencyContact2() &&
              <EmergencyContactCard
                name={this.state.person?.getEmergencyContact2()?.getFirstName() + ' ' + this.state.person?.getEmergencyContact2()?.getLastName()}
                phoneNumber={this.state.person?.getEmergencyContact2()?.getPhoneNumber()}
                email={this.state.person?.getEmergencyContact2()?.getEmail()}
                relation={this.state.person?.getEmergencyContact2()?.getRelationship()}
              />
            }
          </div>
        </Card>*/}

        <div style={{ display: 'flex', flexDirection: (window.innerWidth < 700 ? 'column' : 'row'), justifyContent: 'center', alignItems: 'center' }}>
          <Button outlined component={Link} to={'/people/' + this.props.match?.params?.id + '/evaluator/training'} style={{ margin: '10px', padding: '10px', width: (window.innerWidth < 700 ? '80%' : '40%') }}>View Training</Button>
          <Button outlined href={'https://dashboard.stripe.com/test/customers/' + this.state.person?.getStripeCustomerId()} style={{ margin: '10px', padding: '10px', width: (window.innerWidth < 700 ? '80%' : '40%') }}>View Stripe Transactions</Button>
          <Button outlined component={Link} to={'/people/' + this.props.match?.params?.id + '/evaluator/certifications'} style={{ margin: '10px', padding: '10px', width: (window.innerWidth < 700 ? '80%' : '40%') }}>View Certifications</Button>
        </div>
        <div style={{ display: 'flex', flexDirection: (window.innerWidth < 700 ? 'column' : 'row'), justifyContent: 'center', alignItems: 'center' }}>
          <Button outlined href={'https://dashboard.checkr.com/candidates/' + this.state.person?.getEvaluator()?.getCheckrCandidateId()} style={{ margin: '10px', padding: '10px', width: (window.innerWidth < 700 ? '80%' : '40%') }}>View Checkr Candidate</Button>
          <Button outlined component={Link} to={'/people/' + this.props.match?.params?.id + '/evaluator/evaluations'} style={{ margin: '10px', padding: '10px', width: (window.innerWidth < 700 ? '80%' : '40%') }}>View Evaluator Evaluations</Button>
          <Button outlined component={Link} to={'/people/' + this.props.match?.params?.id + '/evaluee/evaluations'} style={{ margin: '10px', padding: '10px', width: (window.innerWidth < 700 ? '80%' : '40%') }}>View Evaluee Evaluations</Button>
        </div>
        <Divider variant="middle" style={{ marginTop: '20px', marginBottom: '20px' }} />
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', marginBottom: '20px', alignItems: 'center' }}>
          <Button
            danger
            style={{ margin: '10px', padding: '10px', width: (window.innerWidth < 700 ? '80%' : '40%') }}
            onClick={() => this.onSuspendAccount()}
          >
            {this.state?.person?.getSuspended() ? 'Unsuspend Account' : 'Suspend Account'}
          </Button>
        </div>
      </Container>
    );
  }
}

Person.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Person);
