// eslint-disable-next-line no-undef
const PORTAL_URL = process.env.REACT_APP_PORTAL_URL;

// Container for all elements to be rendered in the App
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Redirect } from 'react-router-dom';

// Components
import { AlertList, ConfirmationModal } from '../components';
import PageWrapper from './PageWrapper';

// Pages
import Person from '../pages/people/[id]';
import People from '../pages/people';
import EvaluatorTraining from '../pages/people/[id]/evaluator/training';
import PersonTransactions from '../pages/people/[id]/transactions';
import EvaluatorCertifications from '../pages/people/[id]/evaluator/certifications';
import EvaluatorEvaluations from '../pages/people/[id]/evaluator/evaluations';
import EvalueeEvaluations from '../pages/people/[id]/evaluee/evaluations';
import ScheduleEvaluation from '../pages/evaluations/schedule';

import Certifications from '../pages/certifications';
import Checkr from '../pages/background-checks';
import Coupons from '../pages/coupons';
import Coupon from '../pages/coupons/[id]';
import CreateCoupon from '../pages/coupons/create';

import Evaluations from '../pages/evaluations';
import Evaluation from '../pages/evaluations/[id]';

import Groups from '../pages/groups';
import CreateGroup from '../pages/groups/create';
import Group from '../pages/groups/[id]';
import GroupAdmins from '../pages/groups/[id]/admins';
import GroupEvaluators from '../pages/groups/[id]/evaluators';
import GroupInvitations from '../pages/groups/[id]/invitations';
import CreateGroupInvite from '../pages/groups/[id]/invitations/create';
import GroupEvaluations from '../pages/groups/[id]/evaluations';

import Training from '../pages/training';
import CreateTrainingModule from '../pages/training/training-modules/create';
import TrainingModules from '../pages/training/training-modules';
import TrainingModule from '../pages/training/training-modules/[id]';
import CreateErgonar from '../pages/training/ergonars/create';
import Ergonars from '../pages/training/ergonars';
import Ergonar from '../pages/training/ergonars/[id]';

function RouteAndWrapper({ children, path, exact = false }) {
  return (
    <Route path={path} exact={exact}>
      <PageWrapper>
        {children}
      </PageWrapper>
    </Route>
  );
}
RouteAndWrapper.propTypes = {
  children: PropTypes.any.isRequired,
  path: PropTypes.string.isRequired,
  exact: PropTypes.bool
};

// Props: windowSize
export default class Navigation extends Component {
  render() {
    return (
      <div style={{ paddingBottom: '120px' }}>
        <AlertList />
        <ConfirmationModal />
        <Switch>
          <RouteAndWrapper path="/training" exact>
            <Training />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/training-modules/create' exact>
            <CreateTrainingModule />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/training-modules' exact>
            <TrainingModules />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/training-modules/:id' exact>
            <TrainingModule />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/ergonars/create' exact>
            <CreateErgonar />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/ergonars' exact>
            <Ergonars />
          </RouteAndWrapper>
          <RouteAndWrapper path='/training/ergonars/:id' exact>
            <Ergonar />
          </RouteAndWrapper>

          <RouteAndWrapper path="/certifications" exact>
            <Certifications />
          </RouteAndWrapper>

          <RouteAndWrapper path="/background-checks" exact>
            <Checkr />
          </RouteAndWrapper>

          <RouteAndWrapper path="/coupons" exact>
            <Coupons />
          </RouteAndWrapper>
          <RouteAndWrapper path="/coupons/create" exact>
            <CreateCoupon />
          </RouteAndWrapper>
          <RouteAndWrapper path="/coupons/:id" exact>
            <Coupon />
          </RouteAndWrapper>

          <RouteAndWrapper path="/evaluations" exact>
            <Evaluations />
          </RouteAndWrapper>
          <RouteAndWrapper path='/evaluations/schedule' exact>
            <ScheduleEvaluation />
          </RouteAndWrapper>
          <RouteAndWrapper path="/evaluations/:id" exact>
            <Evaluation />
          </RouteAndWrapper>

          <RouteAndWrapper path="/groups" exact>
            <Groups />
          </RouteAndWrapper>
          <RouteAndWrapper path="/groups/create" exact>
            <CreateGroup />
          </RouteAndWrapper>
          <RouteAndWrapper path="/groups/:id" exact>
            <Group />
          </RouteAndWrapper>
          <RouteAndWrapper path="/groups/:id/admins" exact>
            <GroupAdmins />
          </RouteAndWrapper>
          <RouteAndWrapper path="/groups/:id/evaluators" exact>
            <GroupEvaluators />
          </RouteAndWrapper>
          <RouteAndWrapper path="/groups/:id/invitations" exact>
            <GroupInvitations />
          </RouteAndWrapper>
          <RouteAndWrapper path="/groups/:id/invitations/create" exact>
            <CreateGroupInvite />
          </RouteAndWrapper>
          <RouteAndWrapper path="/groups/:id/evaluations" exact>
            <GroupEvaluations />
          </RouteAndWrapper>

          <RouteAndWrapper path="/people" exact>
            <People />
          </RouteAndWrapper>
          <RouteAndWrapper path='/people/:id/evaluator/training' exact>
            <EvaluatorTraining />
          </RouteAndWrapper>
          <RouteAndWrapper path='/people/:id/transactions' exact>
            <PersonTransactions />
          </RouteAndWrapper>
          <RouteAndWrapper path='/people/:id/evaluator/certifications' exact>
            <EvaluatorCertifications />
          </RouteAndWrapper>
          <RouteAndWrapper path='/people/:id/evaluator/evaluations' exact>
            <EvaluatorEvaluations />
          </RouteAndWrapper>
          <RouteAndWrapper path='/people/:id/evaluee/evaluations' exact>
            <EvalueeEvaluations />
          </RouteAndWrapper>
          <RouteAndWrapper path='/people/:id' exact>
            <Person />
          </RouteAndWrapper>

          <Route path='/auth/login' component={() => {
            window.location.href = PORTAL_URL + '/auth/login';
            return null;
          }} />

          <Route path='/portal' component={() => {
            window.location.href = PORTAL_URL;
            return null;
          }} />

          <RouteAndWrapper path='/'>
            <Redirect to='/people' />
          </RouteAndWrapper>
        </Switch>
      </div>
    );
  }
}
