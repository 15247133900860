import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button } from '@lexcelon/react-util';
import { Typography } from '@mui/material';

// Components
import { BackToButton, EvaluationsTable } from '../../../../../components';

// Icons
import AddIcon from '@mui/icons-material/Add';

// API
import { listEvaluatorEvaluations, getPerson } from '../../../../../api';

// Alerts
import { setError } from '../../../../../alerts';

class EvaluatorEvaluations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evaluations: []
    };
  }

  componentDidMount() {
    const personId = this.props.match?.params?.id;
    getPerson(personId).then(person => {
      if (person != null) {
        this.setState({ person: person });
      }
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve person.');
    });

    this.setState({ isLoading: true });
    listEvaluatorEvaluations({ evaluatorId: personId }).then(({ results }) => {
      if (results != null) {
        this.setState({ evaluations: results });
      }
      this.setState({ isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve Evaluations.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/people/' + this.state.person?.getId()} description={this.state.person?.getFullName()} />
        </div>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Evaluations by {this.state.person?.getFullName()}</Typography>
        <EvaluationsTable
          evaluations={this.state.evaluations}
          title={
            <Button component={Link} to={'/evaluations/schedule?evaluatorId=' + this.props.match?.params?.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
              Schedule New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}

EvaluatorEvaluations.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(EvaluatorEvaluations);
