import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Typography } from '@mui/material';

// Components
import { Button } from '@lexcelon/react-util';
import { EvaluationsTable } from '../../components';

// API
import { listEvaluations } from '../../api';

// Alerts
import { setError } from '../../alerts';

// Icons
import AddIcon from '@mui/icons-material/Add';

class Evaluations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evaluations: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listEvaluations().then(({ results }) => {
      if (results != null) {
        this.setState({ evaluations: results });
      }
      this.setState({ isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve Evaluations.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Evaluations</Typography>
        <EvaluationsTable
          title={
            <Button component={Link} to={'/evaluations/schedule'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
              Schedule New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          evaluations={this.state.evaluations}
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}

Evaluations.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Evaluations);
