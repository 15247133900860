import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { Container, Typography } from '@mui/material';

// Components
import { BackToButton, ErgonarForm } from '../../../../components';

class CreateErgonar extends Component {

  constructor(props) {
    super(props);

    this.state = {
      segments: null,
      redirect: false,
      ergonar: null
    };
  }

  onSuccess = (ergonar) => {
    this.setState({ redirect: true, ergonar: ergonar });
  };

  render() {
    if (this.state.redirect && this.state.ergonar != null) {
      return <Redirect to={'/training/ergonars/' + this.state.ergonar.id} />;
    }

    return (
      <Container>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/training/ergonars'} description='Ergonars' />
        </div>

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Create an Ergonar</Typography>

        <ErgonarForm onSuccess={this.onSuccess} />
      </Container>
    );
  }
}

export default CreateErgonar;
