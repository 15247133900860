import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

import { getErgonar, toggleErgonarActive } from '../../../../api';

// Components
import { LineItem } from '@lexcelon/react-util';
import { BackToButton, ErgonarForm } from '../../../../components';

// Constants
import { ROUSING_RED, FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';
import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

class Ergonar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ergonar: null,
      editMode: false,
      redirect: false
    };

    this.onEditSuccess = this.onEditSuccess.bind(this);
    this.loadErgonar = this.loadErgonar.bind(this);
    this.toggleErgonarStatus = this.toggleErgonarStatus.bind(this);
  }

  componentDidMount() {
    this.loadErgonar();
  }

  loadErgonar() {
    const ergonarId = this.props.match?.params?.id;
    getErgonar(ergonarId).then((ergonar) => {
      this.setState({
        ergonar: ergonar
      });
    }).catch((error) => {
      setError(error ?? 'Unable to load that requested ergonar.');

      this.setState({
        redirect: true
      });
    });
  }

  onEditSuccess() {
    this.setState({
      editMode: false
    });

    this.loadErgonar();
  }

  toggleErgonarStatus() {
    const newStatus = !this.state.ergonar.isActive;

    toggleErgonarActive({ id: this.state.ergonar.id, isActive: newStatus }).then(() => {
      setSuccess('Successfully ' + (newStatus ? 'activated' : 'deactivated') + ' the ergonar.');

      this.loadErgonar();
    }).catch((error) => {
      setError(error ?? 'Unable to toggle ergonar status, please try again.');
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/training/ergonars'} />;
    }

    if (this.state.editMode) {
      return (
        <Container>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Edit Ergonar</Typography>
          <ErgonarForm
            onSuccess={this.onEditSuccess}
            ergonar={this.state.ergonar}
            onClose={() => this.setState({ editMode: false })}
          />
        </Container>
      );
    }

    return (
      <Container>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/training/ergonars'} description='Ergonars' />
        </div>

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.ergonar?.getName()}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center' }}>Ergonar</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: ROUSING_RED, fontStyle: 'italic' }}>{this.state.ergonar?.getIsActive() ? 'Active' : 'Inactive'}</Typography>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5em', marginBottom: '0.3em' }}>
          <img src={this.state.ergonar?.getDisplayImageUrl()} style={{ width: '150px', height: 'auto' }} />
        </div>

        <LineItem
          value={'$' + this.state.ergonar?.getPrice()}
          description='Price'
        />

        <LineItem
          value={this.state.ergonar?.getSegments()?.map((element) => (
            element?.getName()
          )).join(', ')}
          description='Segment'
        />

        <LineItem
          value={enumToDisplayString(this.state.ergonar?.getType())}
          description='Type'
        />

        <LineItem
          value={this.state.ergonar?.getDescription()}
          description='Description'
        />

        <LineItem
          value={this.state.ergonar?.getNotes()}
          description='Notes'
        />

        <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Zoom Ergonar</Typography>

        <LineItem
          value={this.state.ergonar?.getDateTime()?.toLocaleString(FULL_DATE_TIME)}
          description='DateTime'
        />

        <LineItem
          value={this.state.ergonar?.getEstimatedDuration()}
          description='Estimated Duration'
        />

        <LineItem
          value={this.state.ergonar?.getZoomWebinarId()}
          description='Zoom Webinar ID'
        />
        <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Video Ergonar</Typography>

        <LineItem
          value={this.state.ergonar?.getVideoUrl()}
          description='Video URL'
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{
              margin: '10px',
              padding: '10px',
              width: (window.innerWidth < 700 ? '80%' : '40%')
            }}
            onClick={() => {
              this.setState({
                editMode: true
              });
            }}
            secondary
          >
          Edit Ergonar
          </Button>

          <Button
            style={{
              margin: '10px',
              padding: '10px',
              width: (window.innerWidth < 700 ? '80%' : '40%')
            }}
            onClick={() => {
              this.toggleErgonarStatus();
            }}
            danger
          >
            {this.state.ergonar?.getIsActive() ? 'Set Inactive' : 'Set Active'}
          </Button>
        </div>

      </Container>
    );
  }
}

Ergonar.propTypes = {
  match: PropTypes.any
};

export default withRouter(Ergonar);
