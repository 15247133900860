import React, { useEffect, useMemo, useReducer, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Box, Grid, MenuItem, TextField, Typography } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';
import { setError } from '../alerts';
import { US_STATES } from '@ergonauts/ergo-algo-react/core/constants';
// import { OpenLocationCode } from 'open-location-code'; We can maybe use this to convert the plus god returned in getDetails to a lat/lng

/** @typedef {{line1: string; line2: string; city: string; state: string; zip: string}} AddressType */

/**
 * @param {object} props
 * @param {(address: AddressType) => void} [props.onChange]
 * @param {boolean} [props.isLoading]
 * @param {(keyof AddressType)[]} [props.requiredFields]
 * @param {AddressType} [props.initialValue]
 */
const AddressInput = ({ onChange, isLoading: isLoadingProp = false, requiredFields = [], initialValue }) => {
  /** @type {[AddressType, React.Dispatch<Partial<AddressType> | null>]} */
  const [address, updateAddress] = useReducer(
    (
      /** @type {AddressType} */
      state,
      /** @type {Partial<AddressType> | null} */
      action
    ) => {
      let newState = { line1: '', line2: '', city: '', state: '', zip: '' };
      if (action != null) {
        newState = { ...state, ...action };
      }
      // !!!!! THIS IS WHERE WE UPDATE THE PARENT !!!!!
      onChange?.(newState);
      return newState;
    },
    initialValue ?? { line1: '', line2: '', city: '', state: '', zip: '' }
  );

  /** @type {[google.maps.places.AutocompletePrediction | null, React.Dispatch<React.SetStateAction<google.maps.places.AutocompletePrediction | null>>]} */ 
  const [value, setValue] = useState(null);
  const [inputValue, setInputValue] = useState('');
  /** @type {[google.maps.places.AutocompletePrediction[], (arg: google.maps.places.AutocompletePrediction[]) => void]} */ 
  const [options, setOptions] = useState([]);
  /** @type {React.MutableRefObject<google.maps.places.AutocompleteService | null>} */
  const autocompleteService = useRef(null);

  const autoCompleteSession = useRef(new window.google.maps.places.AutocompleteSessionToken());
  
  const isLoading = isLoadingProp;

  const fetchPredictions = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current?.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    if (value != null) {
      const places = new window.google.maps.places.PlacesService(document.createElement('div'));
      try {
        places.getDetails({ placeId: value.place_id }, (place, status) => {
          const getComponent = (type) => place?.address_components?.find((val) => val.types.includes(type));
          if (status === 'OK') {
            const streetNum = getComponent('street_number')?.long_name;
            const streetName = getComponent('route')?.long_name;
            const suite = getComponent('subpremise')?.long_name;
            const room = getComponent('room')?.long_name;
            const floor = getComponent('floor')?.long_name;
            const line2 = [suite ?? '', floor ?? '' , room ?? ''].join(' ').trim();
            const city = getComponent('locality')?.long_name ?? getComponent('administrative_area_level_2')?.long_name ?? getComponent('political')?.long_name;
            const state = getComponent('administrative_area_level_1')?.short_name;
            const zip = getComponent('postal_code')?.long_name;

            if (streetNum == null && requiredFields.includes('line1')) {
              setError('Selected address is not valid: no street number');
              setInputValue('');
              setValue(null);
              setOptions([]);
              return;
            }
            else if (streetName == null && requiredFields.includes('line1')) {
              setError('Selected address is not valid: no street name');
              setInputValue('');
              setValue(null);
              setOptions([]);
              return;
            }
            else if (city == null && requiredFields.includes('city')) {
              setError('Selected address is not valid: no city');
              setInputValue('');
              setValue(null);
              setOptions([]);
              return;
            }
            else if (state == null && requiredFields.includes('state')) {
              setError('Selected address is not valid: no state');
              setInputValue('');
              setValue(null);
              setOptions([]);
              return;
            }
            else if (zip == null && requiredFields.includes('zip')) {
              setError('Selected address is not valid: no zip code');
              setInputValue('');
              setValue(null);
              setOptions([]);
              return;
            }
            else {
              updateAddress({
                line1: streetName != null && streetNum != null ? `${streetNum} ${streetName}` : '',
                line2: line2 ?? '',
                city: city ?? '',
                state: state ?? '',
                zip: zip ?? '',
              });
            }
          }
          else {
            setError('There was a problem loading the address you selected, please try again.');
          }
        });
      }
      finally {
        autoCompleteSession.current = new window.google.maps.places.AutocompleteSessionToken();
      }
    }
  }, [value]);

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (autocompleteService.current == null) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetchPredictions(
      /** @type {google.maps.places.AutocompletionRequest} */ 
      {
        input: inputValue,
        componentRestrictions: { country: ['us'] },
        types: [],
        sessionToken: autoCompleteSession.current,
      }, 
      (
      /** @type {google.maps.places.AutocompletePrediction[] | null} */
        results
      ) => {
        if (active) {
        /** @type {google.maps.places.AutocompletePrediction[]} */
          let newOptions = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  
  return (
    <>
      <Autocomplete
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.description
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(_event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setValue(newValue);
          updateAddress(null);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search for a business or an address" 
            style={{ width: '100%', marginBottom: '10px' }} />
        )}
        renderOption={(props, option) => {
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length]),
          );

          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={LocationOnIcon}
                    sx={{ color: 'text.secondary', mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}

                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />

      {/* Line 1 */}
      <TextField
        required={requiredFields.includes('line1')}
        name='line1'
        id='line1'
        label='Line 1'
        style={{ width: '100%', marginBottom: '10px' }}
        value={address.line1}
        variant='filled'
        disabled={true}
      />

      {/* Line 2 */}
      <TextField
        required={requiredFields.includes('line2')}
        name='line2'
        id='line2'
        label='Line 2'
        style={{ width: '100%', marginBottom: '10px' }}
        value={address.line2}
        onChange={(e) => updateAddress({ line2: e.target.value })}
        variant='filled'
        disabled={isLoading}
      />

      {/* City ST Zip */}
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
        <TextField
          required={requiredFields.includes('city')}
          name='city'
          label='City'
          style={{ width: '49%' }}
          value={address.city}
          variant='filled'
          disabled={true}
        />
        <TextField
          required={requiredFields.includes('state')}
          select
          name='state'
          label='State'
          style={{ width: '24%' }}
          value={address.state}
          variant='filled'
          disabled={true}
        >
          {US_STATES.map((state, i) => (
            <MenuItem key={i} value={state}>
              {state}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          required={requiredFields.includes('zip')}
          name='zip'
          label='Zip'
          style={{ width: '24%' }}
          value={address.zip}
          variant='filled'
          disabled={true}
          inputProps={{ maxLength: 5 }}
        />
      </div>
    </>
  );
};

AddressInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  requiredFields: PropTypes.arrayOf(PropTypes.string),
  initialValue: PropTypes.shape({
    line1: PropTypes.string,
    line2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
  }),
};

export default AddressInput;