import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { MaterialTable } from '@lexcelon/react-util';

// API
import { listPeople } from '../../api';

// Alerts
import { setError } from '../../alerts';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import { DateTime } from 'luxon';

import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

// Constants
const COLUMNS = [
  {
    title: 'Training Payment Date',
    render: rowData => new Date(rowData.getEvaluator()?.evaluatorCertifications[0]?.evaluatorCertificationTrainingPayments[0]?.createdAt).toLocaleDateString() == 'Invalid Date' ? 'N/A' : new Date(rowData.getEvaluator()?.evaluatorCertifications[0]?.evaluatorCertificationTrainingPayments[0]?.createdAt).toLocaleString(),
    value: rowData => rowData.getEvaluator()?.evaluatorCertifications[0]?.evaluatorCertificationTrainingPayments[0]?.createdAt
    // customSort: (a, b) => !new Date(a.getEvaluator()?.evaluatorCertifications[0]?.evaluatorCertificationTrainingPayments[0]?.createdAt).getTime()?.toString()?.localeCompare(new Date(b.getEvaluator()?.evaluatorCertifications[0]?.evaluatorCertificationTrainingPayments[0]?.createdAt).getTime()?.toString()),
  },
  {
    title: 'User Creation Date',
    render: rowData =>  new Date(rowData.getCreatedAt()).toLocaleString(),
    value: rowData =>  rowData.getCreatedAt(),
    // customSort: (a, b) => !new Date(a.getCreatedAt()).getTime()?.toString()?.localeCompare(new Date(b.getCreatedAt()).getTime()?.toString())
  },
  {
    value: rowData => rowData.getFirstName(),
    title: 'First Name'
  },
  {
    value: rowData => rowData.getLastName(),
    title: 'Last Name'
  },
  {
    value: rowData => rowData.getEmail(),
    title: 'Email'
  },
  {
    title: 'Location',
    value: rowData => (
      rowData.getMailingAddress()?.getCity() && rowData.getMailingAddress()?.getState() ? rowData.getMailingAddress()?.getCity() + ', ' + rowData.getMailingAddress()?.getState() : ''
    )
  },
  {
    title: 'Groups',
    customFilterAndSearch: (term, rowData) => {
      if (rowData.getGroupAssociations() == null) return false;
      return rowData.getGroupAssociations().findIndex(assoc => assoc.getGroup()?.getName()?.toLowerCase().includes(term?.toLowerCase()) || assoc.getRole()?.getRole()?.toLowerCase().includes(term?.toLowerCase())) != -1;
    },
    render: rowData => (
      rowData.getGroupAssociations()?.map((association, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          {association.getGroup()?.getName() + ' ' + enumToDisplayString(association.getRole()?.getRole())}
        </div>
      ))
    )
  },
  // {
  //   title: 'DBA Name',
  //   customFilterAndSearch: (term, rowData) => {
  //     if (rowData.getEvaluator()?.getDoingBusinessAsName() == null) return false;
  //     return rowData.getEvaluator().getDoingBusinessAsName().toLowerCase()?.includes(term?.toLowerCase());
  //   },
  //   render: rowData => (
  //     rowData?.getEvaluator()?.getDoingBusinessAsName()
  //   )
  // },
  // {
  //   title: 'Heard About From',
  //   value: rowData => rowData.heardAboutFrom
  // },
  {
    title: 'Has Paid for Training',
    value: rowData => rowData.getEvaluator()?.evaluatorCertifications[0]?.evaluatorCertificationTrainingPayments?.length > 0 ? 'Yes' :  'No',
    search: 'select'
  },
  {
    title: 'Has Active Subscription',
    value: rowData => rowData.getEvaluator()?.evaluatorCertifications[0]?.evaluatorCertificationSubscriptions[0]?.subscriptionEnd?.ts > DateTime.now().ts ? 'Yes' :  'No',
    search: 'select'
  },
  {
    value: rowData => rowData.getEvaluatorInterest() ? 'Yes' : 'No',
    title: 'Evaluator Interest',
    search: 'select',
    selectOptions: [
      { label: 'Yes', value: 'Yes' },
      { label: 'No', value: 'No' }
    ]
  },
  {
    value: rowData => rowData.getSuspended() ? 'Suspended' : 'Active',
    title: 'Is Suspended',
    search: 'select',
    selectOptions: [
      { label: 'Suspended', value: 'Suspended' },
      { label: 'Active', value: 'Active' }
    ]
  },
  {
    title: 'View',
    search: 'none',
    render: rowData => (
      <Link to={'/people/' + rowData.getId()}><VisibilityIcon /></Link>
    )
  }
];

class People extends Component {
  constructor(props) {
    super(props);

    this.state = {
      people: [],
      count: 0,
      options: {},
      isLoading: false
    };
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    listPeople().then(({ results }) => {
      this.setState({ people: results, isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve people.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>People & Accounts</Typography>

        <MaterialTable
          title={''}
          data={{
            mode: 'list',
            rows: this.state.people,
            columns: COLUMNS
          }}
          options={{
            pageSize: 5,
            search: 'column'
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

export default People;
