import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';
import { Link } from 'react-router-dom';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Icons
import DownloadIcon from '@mui/icons-material/Download';
import RefreshIcon from '@mui/icons-material/Refresh';

import { generateEvaluatorCertificationCertificate } from '../api';

// Alerts
import { setError, setSuccess } from '../alerts';

const COLUMNS = [
  {
    title: 'Name',
    value: rowData => rowData?.getEvaluator()?.getPerson()?.getFullName(),
    render: rowData =>
      <Link to={'/people/' + rowData?.getEvaluator()?.getPersonId()}>{rowData?.getEvaluator()?.getPerson()?.getFullName()}</Link>,
    customFilterAndSearch: (term, rowData) => rowData?.getEvaluator()?.getPerson()?.getFullName()?.toLowerCase()?.includes(term?.toLowerCase())
  },
  {
    title: 'Segment',
    value: rowData => rowData?.getSegment()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getSegment()?.getName()?.toLowerCase()?.includes(term?.toLowerCase())
  },
  {
    title: 'Subscription Start',
    value: rowData => getMostRecentSubscription(rowData?.getEvaluatorCertificationSubscriptions())?.getSubscriptionStart()?.toLocaleString(FULL_DATE_TIME),
    customFilterAndSearch: (term, rowData) => getMostRecentSubscription(rowData?.getEvaluatorCertificationSubscriptions())?.getSubscriptionStart()?.toLocaleString(FULL_DATE_TIME).includes(term),
  },
  {
    title: 'Subscription End',
    value: rowData => getMostRecentSubscription(rowData?.getEvaluatorCertificationSubscriptions())?.getSubscriptionEnd()?.toLocaleString(FULL_DATE_TIME),
    customFilterAndSearch: (term, rowData) => getMostRecentSubscription(rowData?.getEvaluatorCertificationSubscriptions())?.getSubscriptionEnd()?.toLocaleString(FULL_DATE_TIME).includes(term),
  },
  {
    title: 'Training Payment',
    value: rowData => rowData.getHasPurchasedTraining() ? 'Paid' : 'Unpaid'
  },
  {
    title: 'Training Completion',
    value: rowData => rowData.getHasCompletedTraining() ? 'Completed' : 'In Progress'
  },
  {
    title: 'Download Certificate',
    value: () => null,
    render: rowData => {
      let certUrl = getMostRecentCert(rowData.getEvaluatorCertificationCertificates())?.getCertificateUrl();
      return certUrl != null ? (
        <Button
          variant='contained'
          href={getMostRecentCert(rowData.getEvaluatorCertificationCertificates())?.getCertificateUrl()}
          target='_blank'
          rel='noreferrer'>
          <DownloadIcon />
        </Button>
      ) : null;
    }
  },
  {
    title: 'Generate Certificate',
    value: () => null,
    render: rowData => {
      let certificate = getMostRecentCert(rowData.getEvaluatorCertificationCertificates());
      let evaluatorId = rowData?.getEvaluator()?.getPersonId();
      return certificate != null ? (
        <Button
          variant='contained'
          onClick={() => generateEvaluatorCertificate(evaluatorId, certificate?.getId())}
        >
          <RefreshIcon />
        </Button>
      ) : null;
    }
  }
];

// Gets most recent based on subscription start date
function getMostRecentSubscription(subscriptions) {
  var mostRecentSubscription = subscriptions?.length > 0 ? subscriptions[0] : null;
  for (var subscription of subscriptions) {
    if (+subscription?.getSubscriptionStart() >= +mostRecentSubscription?.getSubscriptionStart()) {
      mostRecentSubscription = subscription;
    }
  }
  return mostRecentSubscription;
}

// Gets most recent based on cert issue date
function getMostRecentCert(certifications) {
  var mostRecentCert = certifications?.length > 0 ? certifications[0] : null;
  for (var certification of certifications) {
    if (+certification?.getExpirationDate() >= +mostRecentCert?.getExpirationDate()) {
      mostRecentCert = certification;
    }
  }
  console.log(mostRecentCert);
  return mostRecentCert;
}

function generateEvaluatorCertificate(evaluatorId, evaluatorCertificationCertificateId) {
  generateEvaluatorCertificationCertificate({ evaluatorId, evaluatorCertificationCertificateId }).then(() => {
    setSuccess('Successfully triggered certificate generation.');
  }).catch((e) => {
    console.log(e);
    setError('Unable to generate certificate.');
  });
}

class EvaluatorCertificationTable extends Component {
  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Certifications</Typography>
        <MaterialTable
          title=''
          data={{
            mode: 'list',
            rows: this.props.certifications,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20
          }}
          isLoading={this.props.isLoading}
        />
      </div>
    );
  }
}

EvaluatorCertificationTable.propTypes = {
  certifications: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default EvaluatorCertificationTable;
