import React from 'react';
import { Redirect } from 'react-router-dom';

export default function AuthRedirect(pathname, authProps) {
  const {
    isLoading,
    isFullyAuthenticated,
    isAdmin
  } = authProps;

  if (!isLoading) {
    if (!isFullyAuthenticated) {
      return <Redirect to='/auth/login' />;
    }
    else if (!isAdmin) {
      return <Redirect to='/portal' />;
    }
  }
  return null;
}
