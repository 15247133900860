import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { Typography } from '@mui/material';

// Components
import { BackToButton, EvaluationsTable } from '../../../../components';
import { Button } from '@lexcelon/react-util';

// Icons
import AddIcon from '@mui/icons-material/Add';

// API
import { listGroupEvaluations, getGroup } from '../../../../api';

// Alerts
import { setError } from '../../../../alerts';

class GroupEvaluations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evaluations: [],
      isLoading: false
    };
  }

  componentDidMount() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      if (group != null) {
        this.setState({ group: group });
      }
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve group.');
    });

    this.setState({ isLoading: true });
    listGroupEvaluations({ groupId }).then(({ results }) => {
      this.setState({ evaluations: results, isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve group evaluations.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/groups/' + this.state.group?.getId()} description={this.state.group?.getName()} />
        </div>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>{this.state.group?.getName()} Evaluations</Typography>
        <EvaluationsTable
          title={
            <Button component={Link} to={'/evaluations/schedule?groupId=' + this.props.match?.params?.id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
              Schedule New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          evaluations={this.state.evaluations}
          isLoading={this.state.isLoading}
        />
      </>
    );
  }
}

GroupEvaluations.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GroupEvaluations);
