import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Icons
import AddIcon from '@mui/icons-material/Add';
import LinkIcon from '@mui/icons-material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';

// API
import { listErgonars } from '../../../api';

// Alerts
import { setError } from '../../../alerts';

const COLUMNS = [
  {
    title: 'View',
    value: rowData => rowData.getId(),
    render: rowData => (
      <Link to={'/training/ergonars/' + rowData.getId()}><VisibilityIcon /></Link>
    ),
  },
  {
    field: 'displayImageUrl',
    title: 'Display Image',
    value: () => null,
    render: rowData => rowData.getDisplayImageUrl() && <img src={rowData.getDisplayImageUrl()} style={{ width: '100px', height: 'auto' }} />
  },
  {
    value: rowData => rowData.getName(),
    title: 'Name'
  },
  {
    value: rowData => rowData?.getDateTime()?.toLocaleString(FULL_DATE_TIME),
    title: 'Date / Time',
    customFilterAndSearch: (term, rowData) => rowData?.getDateTime()?.toLocaleString(FULL_DATE_TIME)?.includes(term)
  },
  {
    title: 'Estimated Duration',
    value: rowData => rowData.getEstimatedDuration() ? rowData.getEstimatedDuration() + ' minutes' : null
  },
  {
    value: rowData => rowData.getZoomWebinarId(),
    title: 'Zoom Webinar ID',
  },
  {
    title: 'Video Url',
    value: rowData => rowData.getVideoUrl(),
    render: rowData => rowData.getVideoUrl() && <a href={rowData.getVideoUrl()}><LinkIcon /></a>
  },
  {
    title: 'Segments',
    value: () => null,
    customFilterAndSearch: (term, rowData) => rowData.getSegments()?.findIndex(segment => segment.getName()?.toLowerCase().includes(term?.toLowerCase())) != -1,
    render: rowData => (
      rowData.getSegments()?.map((segment, index) => (
        <div key={index} style={{ marginBottom: '8px' }}>
          {segment.getName()}
        </div>
      ))
    )
  },
  {
    title: 'Price',
    value: rowData => rowData.getPrice() ? '$' + rowData.getPrice() : null,
  },
  {
    value: rowData => rowData.getIsActive(),
    title: 'Is Active',
    customFilterAndSearch: (term, rowData) => (rowData.isActive && term?.toLowerCase() === 'active') || (!rowData.isActive && term?.toLowerCase() === 'not active')
  }
];

class Ergonars extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ergonars: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listErgonars().then(({ results }) => {
      this.setState({ ergonars: results, isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve ergonars.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Ergonars</Typography>
        <MaterialTable
          title={
            <Button component={Link} to='/training/ergonars/create' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
              Create New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'list',
            rows: this.state.ergonars,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

export default Ergonars;
