import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Checkbox, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// Alerts
import { setError } from '../alerts';

// API
import { createTrainingModule, listSegments, updateTrainingModule } from '../api';

import { MAX_FILE_SIZE_MB } from '@ergonauts/ergo-algo-react/core/constants';

class TrainingModuleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: '',
      notes: '',
      description: '',
      segmentId: 0,
      isActive: true,
      displayImagePath: '',
      formError: false,
      isLoading: true
    };
  }

  // If ergonar is passed in we should populate our state with its values.
  componentDidMount() {
    if (this.props.trainingModule != null) {
      this.setState({
        id: this.props.trainingModule.getId(),
        name: this.props.trainingModule.getName() ?? '',
        segmentId: this.props.trainingModule?.getSegmentId(),
        isActive: this.props.trainingModule.getIsActive() != null ? this.props.trainingModule.getIsActive() : true,
        description: this.props.trainingModule?.getDescription() ?? '',
        notes: this.props.trainingModule.getNotes() ?? ''
      });
    }

    listSegments().then(({ results }) => {
      this.setState({
        segments: results,
        isLoading: false
      });
    }).catch(() => {
      setError('Error loading segments, please try again.');
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    this.setState({
      formError: false
    });
  };

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  onImageInput = (e) => {
    // Set Image for Display
    var input = document.getElementById('displayImagePath');
    // get file size in megabytes, throw error if file is too large
    var size = input.files[0].size / 1e6;
    if (size >= MAX_FILE_SIZE_MB) {
      setError(`Error: Please select a file smaller than ${MAX_FILE_SIZE_MB}MB.`);
      this.setState({ displayImagePath: '' });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });

    var fReader = new FileReader();
    fReader.readAsDataURL(input.files[0]);
    this.setState({ displayImage: input.files[0] });
    fReader.onloadend = function (event) {
      document.getElementById('displayImage').innerHTML = '<image src=\'' + event.target.result + '\' style=\'height: 60px;\'/>';
    };
  };

  hasValidForm = () => {
    // These fields must be provided every time.
    if (this.state.description == '') {
      setError('Error: You must include the training module description.');
      return false;
    }

    if (this.state.description?.length > 2040) {
      setError('Error: Please use a description with 2040 characters or less');
      return false;
    }
    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const validForm = this.hasValidForm();

    this.setState({
      isLoading: true
    });

    if (!validForm) {
      this.setState({ isLoading: false });
      return;
    }

    var form = Object.assign({}, this.state);

    if (form.segmentId == 0) {
      form.segmentId = null;
    }

    if (this.props.trainingModule == null) {
      createTrainingModule(form).then((trainingModule) => {
        this.setState({ isLoading: false });
        this.props.onSuccess(trainingModule);
      }).catch((error) => {
        setError(error ?? 'Error: Unable to complete request, please try again.');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateTrainingModule(form).then((trainingModule) => {
        this.setState({ isLoading: false });
        this.props.onSuccess(trainingModule);
      }).catch((error) => {
        setError(error ?? 'Error: Unable to complete request, please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  formChanged() {
    return !(this.props.trainingModule.getName() === this.state.name &&
    this.props.trainingModule?.getSegmentId() === this.state.segmentId &&
    this.props.trainingModule.getIsActive() === this.state.isActive &&
    this.props.trainingModule?.getDescription() === this.state.description &&
    ((this.props.trainingModule?.getNotes() === null && this.state.notes === '') || (this.props.trainingModule?.getNotes() == this.state.notes)) &&
    this.state.displayImagePath === '');
  }

  render() {
    return (
      <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>

        <TextField
          required
          error={this.state.formError && this.state.name === ''}
          disabled={this.state.isLoading}
          name="name"
          label="Name"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.name}
          onChange={this.onChange}
          variant='filled'
        />

        <TextField
          required
          select
          error={this.state.formError && this.state.segment === ''}
          disabled={this.state.isLoading}
          name="segmentId"
          label="ErgoAlgo™ Segment"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.segment}
          onChange={this.onChange}
          variant='filled'
          defaultValue={this.props.trainingModule?.getSegment()?.getId() || 0}
        >
          <MenuItem value={0}>None</MenuItem>
          {this.state.segments?.map((segment) => (
            <MenuItem key={segment.id} value={segment.id}>{segment.getName()}</MenuItem>
          ))}
        </TextField>

        <TextField
          required
          error={this.state.formError && this.state.description === ''}
          disabled={this.state.isLoading}
          name="description"
          label="Description"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          multiline='true'
          value={this.state.description}
          onChange={this.onChange}
          variant='filled'
        />

        <TextField
          error={this.state.formError && this.state.notes === ''}
          disabled={this.state.isLoading}
          name="notes"
          label="Notes"
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.notes}
          onChange={this.onChange}
          variant='filled'
        />

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '0.3em', marginBottom: '0.3em' }}>
          <input disabled={this.state.isLoading} type="file" accept='.png,.jpg,.jpeg' id="displayImagePath" name="displayImagePath" ref={ref=> this.fileInput = ref} label="Select Image" onChange={this.onImageInput} style={{ width: '60%' }} value={this.state.displayImagePath} />
          <div id="displayImage">
            <Avatar />
          </div>
        </div>

        <FormControlLabel
          control={
            <Checkbox
              disabled={this.state.isLoading}
              checked={this.state.isActive}
              onChange={this.onCheckboxChange}
              name="isActive"
            />
          }
          label="Set Active"
        />

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          {this.props.onClose != null &&
          <Button secondary disabled={this.state.isLoading} style={{ width: '100%', marginRight: '10px' }} onClick={this.props.onClose}>
            Cancel
          </Button>}

          <Button type='submit' isLoading={this.state.isLoading} disabled={this.state.error || (this.props.trainingModule != null && !this.formChanged())} style={{ width: '100%' }}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

TrainingModuleForm.propTypes = {
  trainingModule: PropTypes.object,
  onSuccess: PropTypes.func.isRequired,
  onClose: PropTypes.func
};

export default TrainingModuleForm;
