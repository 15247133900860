import store from '../redux/store';
import { createConfirmation, removeConfirmation, startConfirmationLoading, stopConfirmationLoading } from '../redux/actions/confirmActions';

export function confirm(props) {
  store?.dispatch(createConfirmation(props));
}

export function closeConfirm() {
  store?.dispatch(removeConfirmation());
}

export function startConfirmLoading() {
  store?.dispatch(startConfirmationLoading());
}

export function stopConfirmLoading() {
  store?.dispatch(stopConfirmationLoading());
}
