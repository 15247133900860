/*
* Copyright © 2021 Lexcelon LLC <info@lexcelon.com>
* Licensed for non-distributable use to Ergonauts LLC
*/
import { React, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';

function MultipleSelectChip({ label, values, onUpdate, defaultValues, disabled }) {
  const [currentValue, setCurrentValue] = useState(defaultValues);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setCurrentValue(
      // On autofill we get a the stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );

    onUpdate(value);
  };

  useEffect(() => {
    setCurrentValue(defaultValues);
  }, [defaultValues]);

  return (
    <div>
      <FormControl sx={{ width: '100%', marginBottom: '10px' }}>
        <InputLabel id="chip-label">{label}</InputLabel>
        <Select
          labelId="chip-label"
          id="multiple-chip"
          multiple
          value={currentValue}
          onChange={handleChange}
          disabled={disabled}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          variant='filled'
        >
          {values?.map((value) => (
            <MenuItem
              key={value}
              value={value}
            >
              {value}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

MultipleSelectChip.propTypes = {
  label: PropTypes.string.isRequired,
  values: PropTypes.array,
  onUpdate: PropTypes.func.isRequired,
  defaultValues: PropTypes.array,
  disabled: PropTypes.bool.isRequired
};

export default MultipleSelectChip;
