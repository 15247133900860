import React, { Component } from 'react';
import { Link, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Components
import { Table } from '@lexcelon/react-util';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

// API
import { listBackgroundChecks } from '../../api';

// Alerts
import { setError } from '../../alerts';

// Constants
const COLUMNS = [
  {
    title: 'View',
    render: rowData => (
      <a target='_blank' rel='noreferrer' href={'https://dashboard.checkr.com/candidates/' + rowData.getEvaluator()?.getCheckrCandidateId()}><VisibilityIcon /></a>
    ),
  },
  {
    title: 'Person',
    render: rowData => (
      <Link href={'/people/' + rowData?.getEvaluator()?.getPersonId()}>{rowData?.getEvaluator()?.getPerson()?.getFullName()}</Link>
    ),
    customFilterAndSearch: (term, rowData) => rowData?.getPerson()?.getFullName()?.toLowerCase().includes(term?.toLowerCase())
  },
  {
    field: 'invitationStatus',
    title: 'Invitation Status',
  },
  {
    field: 'reportStatus',
    title: 'Report Status',
  },
  {
    field: 'reportResult',
    title: 'Report Result'
  },
  {
    field: 'reportAdjudication',
    title: 'Report Adjudication'
  },
  {
    field: 'hasPassed',
    title: 'Passed',
    customFilterAndSearch: (term, rowData) => (rowData.isActive && term?.toLowerCase() === 'active') || (!rowData.isActive && term?.toLowerCase() === 'not active')
  },
  {
    title: 'Created At',
    customFilterAndSearch: (term, rowData) => DateTime.fromISO(rowData?.getCreatedAt())?.toLocaleString(FULL_DATE_TIME)?.includes(term),
    render: rowData => rowData.getCreatedAt() && DateTime.fromISO(rowData.getCreatedAt()).toLocaleString(FULL_DATE_TIME)
  },
  {
    title: 'Updated At',
    customFilterAndSearch: (term, rowData) => DateTime.fromISO(rowData?.getUpdatedAt())?.toLocaleString(FULL_DATE_TIME)?.includes(term),
    render: rowData => rowData.getUpdatedAt() && DateTime.fromISO(rowData.getUpdatedAt()).toLocaleString(FULL_DATE_TIME)
  }
];

class Checkr extends Component {
  constructor(props) {
    super(props);

    this.state = {
      backgroundChecks: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listBackgroundChecks().then(({ results }) => {
      this.setState({ backgroundChecks: results, isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve background checks.');
      this.setState({ isLoading: false });
    });
  }

  // updated to use the isLoading prop on table component, but not tested yet
  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Background Checks (Checkr)</Typography>
        <Table
          title={''}
          data={this.state.backgroundChecks}
          columns={COLUMNS}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

export default Checkr;
