import axios from 'axios';
import { clearCookies } from '../redux/actions/authActions';

// Library
import { BackgroundCheck, Coupon, Ergonar, EvaluatorCertification, EvaluatorTraining, Evaluator, Group, Person, TrainingModule, Evaluation, ErgoAlgoSegment, GroupAssociation } from '@ergonauts/ergo-algo-react/core/lib';

const axiosWithCredentials = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_SERVER_URL
});

axiosWithCredentials.interceptors?.response?.use(response => {
  return Promise.resolve(response?.data != null ? (response.data === '' ? null : response.data) : response);
}, error => {
  if (error?.response?.status === 401) clearCookies();
  return Promise.reject(error?.response?.data?.message || error?.response?.data || error);
});

export function updatePerson({ id, firstName, lastName, email, phoneNumber, gender, address }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/person/' + id, {
      firstName,
      lastName,
      email,
      phoneNumber,
      gender,
      address
    }).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function suspendPerson({ personId, suspended }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/person/' + personId + '/suspend', {
      suspended
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function listPeople({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/person', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Person.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function createErgonar({ name, price, segments, description, notes, dateTime, estimatedDuration, zoomWebinarId, videoUrl, isActive, displayImage, type }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();

    data.append('name', name);
    data.append('price', price);
    data.append('segments', JSON.stringify(segments));
    data.append('description', description);
    data.append('notes', notes);
    data.append('isActive', isActive);
    data.append('type', type);

    if (dateTime != null) data.append('dateTime', dateTime.toMillis());
    if (estimatedDuration != null) data.append('estimatedDuration', estimatedDuration);
    if (zoomWebinarId != null) data.append('zoomWebinarId', zoomWebinarId);
    if (videoUrl != null) data.append('videoUrl', videoUrl);
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.post('/admin/ergonar', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      resolve(Ergonar.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

/**
 * The nastiness of this is a nessecity. Can't include fields certain fields that are null so they are validated correctly via JOI.
 * Must stringify segments so its sent correctly, otherwise it seems to only send the first element.
 * We use JSON.parse on a middleware on the receiving end of this axios call.
 * @param {} form
 * @returns
 */
export function updateErgonar({ id, name, price, segments, description, notes, dateTime, estimatedDuration, zoomWebinarId, videoUrl, isActive, displayImage, type }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();

    data.append('name', name);
    data.append('price', price);
    data.append('segments', JSON.stringify(segments));
    data.append('description', description);
    data.append('notes', notes);
    data.append('isActive', isActive);
    data.append('type', type);

    if (dateTime != null) data.append('dateTime', dateTime.toMillis());
    if (estimatedDuration != null) data.append('estimatedDuration', estimatedDuration);
    if (zoomWebinarId != null) data.append('zoomWebinarId', zoomWebinarId);
    if (videoUrl != null) data.append('videoUrl', videoUrl);
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.patch('/admin/ergonar/' + id, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      resolve(Ergonar.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listTrainingModules({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/training-module', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: TrainingModule.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getTrainingModule(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/training-module/' + id).then(res => {
      resolve(TrainingModule.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listErgonars({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/ergonar', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Ergonar.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getErgonar(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/ergonar/' + id).then(res => {
      resolve(Ergonar.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createTrainingModule({ name, segmentId, isActive, description, notes, displayImage }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();

    data.append('name', name);
    data.append('isActive', isActive);
    data.append('description', description);

    if (segmentId != null) data.append('segmentId', segmentId);
    if (notes != null) data.append('notes', notes);
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.post('/admin/training-module', data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      resolve(TrainingModule.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateTrainingModule({ id, name, segmentId, isActive, description, notes, displayImage }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();

    data.append('name', name);
    data.append('isActive', isActive);
    data.append('description', description);

    if (segmentId != null) data.append('segmentId', segmentId);
    if (notes != null) data.append('notes', notes);
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.patch('/admin/training-module/' + id, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      resolve(TrainingModule.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleErgonarActive({ id, isActive }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/ergonar/' + id + '/active', {
      isActive
    }).then(res => {
      resolve(Ergonar.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleTrainingModuleActive({ id, isActive }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/training-module/' + id + '/active', {
      isActive: isActive
    }).then(res => {
      resolve(TrainingModule.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listCoupons({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/coupon', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Coupon.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getCoupon(code) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/coupon/' + code).then(res => {
      resolve(Coupon.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getBackgroundCheck(reportId) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/background-check/' + reportId).then(res => {
      resolve(BackgroundCheck.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listBackgroundChecks({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/background-check', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: BackgroundCheck.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluatorCertifications({ evaluatorId = null, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/evaluator/certification/' + (evaluatorId ?? ''), { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: EvaluatorCertification.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function generateEvaluatorCertificationCertificate({ evaluatorId, evaluatorCertificationCertificateId }) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/admin/evaluator/certification/' + evaluatorId + '/certificate/' + evaluatorCertificationCertificateId + '/generate').then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleCouponActive({ id, isActive }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/coupon/' + id + '/active', {
      isActive: isActive
    }).then(res => {
      resolve(Coupon.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateCoupon({ id, use, maxUseCount, code, percentOff, amountOff, segmentId, ergonarId, isActive }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/coupon/' + id, {
      use,
      maxUseCount,
      code,
      percentOff,
      amountOff,
      segmentId,
      ergonarId,
      isActive
    }).then(res => {
      resolve(Coupon.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function createCoupon({ use, maxUseCount, code, percentOff, amountOff, segmentId, ergonarId, isActive }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/coupon', {
      use,
      maxUseCount,
      code,
      percentOff,
      amountOff,
      segmentId,
      ergonarId,
      isActive
    }).then(res => {
      resolve(Coupon.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateEvaluator({ id, rangeOfTravel, receiveReferrals, notes, line1, line2, city, state, zip, doingBusinessAsName, displayImage }) {
  return new Promise(function(resolve, reject) {
    var data = new FormData();

    data.append('rangeOfTravel', rangeOfTravel);
    data.append('receiveReferrals', receiveReferrals);
    data.append('notes', notes);
    data.append('address', JSON.stringify({ line1: line1, line2: line2, city: city, state: state, zip: zip }));

    if (doingBusinessAsName != null) data.append('doingBusinessAsName', doingBusinessAsName);
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.patch('/admin/evaluator/' + id, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    }).then(res => {
      resolve(Evaluator.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluatorTrainings({ options = {}, evaluatorId = null } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/evaluator/training/' + (evaluatorId ?? ''), { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: EvaluatorTraining.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluations({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/evaluation', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function getEvaluation(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/evaluation/' + id).then(res => {
      resolve(Evaluation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function scheduleEvaluation({ segmentId, scheduledDateTime, evaluatorId, evaluee, address, groupId, evalueeCanViewReport }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/evaluation/', {
      segmentId,
      scheduledDateTime,
      evaluatorId,
      evaluee,
      address,
      groupId,
      evalueeCanViewReport
    }).then(res => {
      resolve(Evaluation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateEvaluation({ id, evaluatorId, segmentId, scheduledDateTime, evaluee, address, evalueeCanViewReport }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/evaluation/' + id, {
      evaluatorId,
      segmentId,
      scheduledDateTime,
      evaluee,
      address,
      evalueeCanViewReport
    }).then(res => {
      resolve(Evaluation.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function deleteEvaluation(id) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.delete('/admin/evaluation/' + id).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluatorEvaluations({ evaluatorId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/evaluation/evaluator/' + evaluatorId, { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvalueeEvaluations({ personId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/evaluation/evaluee/' + personId, { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listEvaluatorEligibleSegments({ evaluatorId, groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/segment/eligible', {
      params: {
        options: encodedOptions,
        evaluatorId,
        groupId
      }
    }).then(({ results, ...props }) => {
      resolve({ results: ErgoAlgoSegment.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroups({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/group', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Group.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function createGroup({ address, name, contractExpiration, maxEvaluators, maxEvaluations, contactName, contactEmail, contactPhoneNumber, evalueeCanViewReport, displayImage }) {
  return new Promise(function (resolve, reject) {
    var data = new FormData();

    data.append('name', name);
    data.append('contractExpiration', contractExpiration.toMillis());
    data.append('contractMaxEvaluators', maxEvaluators);
    data.append('contractMaxEvaluations', maxEvaluations);
    data.append('contactName', contactName);
    data.append('contactEmail', contactEmail);
    data.append('contactPhoneNumber', contactPhoneNumber);
    data.append('evalueeCanViewReport', evalueeCanViewReport);

    if (address != null) data.append('address', JSON.stringify(address));
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.post('/admin/group', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      resolve(Group.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function updateGroup({ id, address, name, contractExpiration, maxEvaluators, maxEvaluations, contactName, contactEmail, contactPhoneNumber, evalueeCanViewReport, displayImage }) {
  return new Promise(function (resolve, reject) {
    var data = new FormData();

    data.append('name', name);
    data.append('contractExpiration', contractExpiration.toMillis());
    data.append('contractMaxEvaluators', maxEvaluators);
    data.append('contractMaxEvaluations', maxEvaluations);
    data.append('contactName', contactName);
    data.append('contactEmail', contactEmail);
    data.append('contactPhoneNumber', contactPhoneNumber);
    data.append('evalueeCanViewReport', evalueeCanViewReport);

    if (address != null) data.append('address', JSON.stringify(address));
    if (displayImage != null) data.append('image', displayImage);

    axiosWithCredentials.patch('/admin/group/' + id, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      resolve(Group.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getGroup(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/group/' + id).then(res => {
      resolve(Group.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupPersonEvaluators({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/group/' + groupId + '/evaluators/filter', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Person.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupAdminAssociations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/group/' + groupId + '/admins', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: GroupAssociation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupEvaluatorAssociations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/group/' + groupId + '/evaluators', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: GroupAssociation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupInvitationAssociations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/group/' + groupId + '/invitations', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: GroupAssociation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function listGroupEvaluations({ groupId, options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/admin/group/' + groupId + '/evaluations', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: Evaluation.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function createGroupInvitation({ groupId, email, role }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/group/' + groupId + '/invitations', {
      email,
      role
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function resendGroupInvitation({ groupId, groupAssociationId }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/group/' + groupId + '/invitations/resend', {
      groupAssociationId: groupAssociationId
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function revokeGroupInvitation({ groupId, groupAssociationId }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/group/' + groupId + '/invitations/revoke', {
      groupAssociationId: groupAssociationId
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleGroupActive({ groupId, isActive }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/group/' + groupId + '/active', {
      isActive: isActive
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function toggleGroupAssociationActive({ groupId, associationId, isActive }) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.patch('/admin/group/' + groupId + '/association/' + associationId + '/active', {
      isActive: isActive
    }).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function regenerateReport(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/admin/evaluation/' + id + '/generate-report').then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function listSegments({ options = {} } = {}) {
  let encodedOptions = encodeURI(JSON.stringify(options));
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.get('/segment', { params: { options: encodedOptions } }).then(({ results, ...props }) => {
      resolve({ results: ErgoAlgoSegment.thawList(results), ...props });
    }).catch(error => {
      reject(error);
    });
  });
}

export function resendEvalueeInvitation(evaluationId) {
  return new Promise(function (resolve, reject) {
    axiosWithCredentials.post('/evaluation/invitation/resend/' + evaluationId).then(res => {
      resolve(res);
    }).catch(error => {
      reject(error);
    });
  });
}

export function getPerson(id) {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/admin/person/' + (id ?? '')).then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function getSelf() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.get('/person/').then(res => {
      resolve(Person.thaw(res));
    }).catch(error => {
      reject(error);
    });
  });
}

export function logoutPerson() {
  return new Promise(function(resolve, reject) {
    axiosWithCredentials.post('/person/logout').then(() => {
      resolve();
    }).catch(error => {
      reject(error);
    });
  });
}
