import Cookies from 'js-cookie';
import { AlertSeverity, setAlert } from './alertActions';

// Server Code
import { getSelf, logoutPerson as logoutPersonFromServer } from '../../api';

// Constants
export const IS_LOADING = 'IS_LOADING';
export const IS_NOT_LOADING = 'IS_NOT_LOADING';
export const SOFT_AUTHENTICATED = 'SOFT_AUTHENTICATED';
export const FULLY_AUTHENTICATED = 'FULLY_AUTHENTICATED';
export const NOT_AUTHENTICATED = 'NOT_AUTHENTICATED';

const SOFT_COOKIE_NAME = process.env.REACT_APP_SOFT_COOKIE_NAME;
const FULL_COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME;

function setSuccess(dispatch, msg) {
  setAlert(dispatch, msg, AlertSeverity.SUCCESS, true);
}

export const startLoading = () => ({
  type: IS_LOADING
});

export const stopLoading = () => ({
  type: IS_NOT_LOADING
});

export const softAuthenticated = (isAdmin = false, twoFactorAuthSetupRequired = false) => ({
  type: SOFT_AUTHENTICATED,
  isAdmin,
  twoFactorAuthSetupRequired
});

export const fullyAuthenticated = (isAdmin = false) => ({
  type: FULLY_AUTHENTICATED,
  isAdmin
});

export const notAuthenticated = () => ({
  type: NOT_AUTHENTICATED
});

export const checkAuthentication = (dispatch, isSoftAuthenticated, isFullyAuthenticated) => {
  dispatch(startLoading());

  // If we falsely have the person logged in, log them out and clear all cookies.
  if ((Cookies.get(SOFT_COOKIE_NAME) == null && isSoftAuthenticated) || (Cookies.get(FULL_COOKIE_NAME) == null && isFullyAuthenticated)) {
    logoutPerson(dispatch);
  }

  else if (Cookies.get(SOFT_COOKIE_NAME) != null && !isSoftAuthenticated) {
    // If both cookies are there, fully authenticate
    if (Cookies.get(FULL_COOKIE_NAME) != null && !isFullyAuthenticated) {
      getSelf().then(person => {
        dispatch(fullyAuthenticated(person?.getIsAdmin()));
      }).catch(error => {
        console.log(error);
        dispatch(fullyAuthenticated(false));
      });
    }

    // If only the soft cookie is there, fully logout the person to prevent a weird limbo state
    else if (Cookies.get(FULL_COOKIE_NAME) == null) {
      logoutPerson(dispatch);
    }
  }

  else {
    dispatch(stopLoading());
  }
};

export const logoutPerson = (dispatch) => {
  // Revoke tokens and unauthenticate in redux state
  if (Cookies.get(SOFT_COOKIE_NAME) != null && Cookies.get(FULL_COOKIE_NAME) != null) {
    logoutPersonFromServer().then(() => {
      setSuccess('Successfully logged out.');
      clearCookies(dispatch);
    }).catch(() => {
      clearCookies(dispatch);
    });
  }
  else clearCookies(dispatch);
};

export const clearCookies = (dispatch = null) => {
  Cookies.remove(SOFT_COOKIE_NAME, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  Cookies.remove(FULL_COOKIE_NAME, { path: '/', domain: process.env.REACT_APP_COOKIE_DOMAIN });
  if (dispatch != null) dispatch(notAuthenticated());
};
