import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import Navigation from './navigation';
import AppBar from './navigation/AppBar';
import Footer from './navigation/Footer';
import ScrollToTop from './ScrollToTop';
import { ThemeProvider } from '@mui/material';
import theme from '@ergonauts/ergo-algo-react/core/theme';

// Redux
import { Provider } from 'react-redux';
import store from './redux/store';

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <ThemeProvider theme={theme}>
          <div style={{ position: 'relative', minHeight: '100vh' }}>
            <AppBar />
            <Navigation />
            <Footer />
          </div>
        </ThemeProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
