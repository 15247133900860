import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { MaterialTable } from '@lexcelon/react-util';
import { FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';
import { Link } from 'react-router-dom';

import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';

const COLUMNS = [
  {
    title: 'Name',
    value: rowData => rowData?.getEvaluator()?.getPerson()?.getFullName(),
    render: rowData =>
      <Link to={'/people/' + rowData?.getEvaluator()?.getPersonId()}>{rowData?.getEvaluator()?.getPerson()?.getFullName()}</Link>,
    customFilterAndSearch: (term, rowData) => rowData?.getEvaluator()?.getPerson()?.getFullName()?.toLowerCase()?.includes(term?.toLowerCase())
  },
  {
    title: 'Training Module',
    value: rowData => rowData?.getTrainingModuleVersion()?.getTrainingModule()?.getName(),
    customFilterAndSearch: (term, rowData) => rowData?.getTrainingModuleVersion()?.getTrainingModule()?.getName()?.toLowerCase()?.includes(term?.toLowerCase())
  },
  {
    field: 'status',
    title: 'Status',
    value: rowData => enumToDisplayString(rowData?.getStatus())
  },
  {
    title: 'Completed Timestamp',
    value: rowData => rowData?.getCompletedTimestamp()?.toLocaleString(FULL_DATE_TIME),
  },
  {
    title: 'Valid To',
    value: rowData => rowData?.getValidTo()?.toLocaleString(FULL_DATE_TIME),
  },
];

class EvaluatorTrainingTable extends Component {
  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Training</Typography>
        <MaterialTable
          title=''
          data={{
            mode: 'list',
            rows: this.props.training,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20
          }}
          isLoading={this.props.isLoading}
        />
      </div>
    );
  }
}

EvaluatorTrainingTable.propTypes = {
  training: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default EvaluatorTrainingTable;
