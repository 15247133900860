import React, { Component } from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Autocomplete, Container, FormHelperText, MenuItem, TextField, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import qs from 'qs';

// API
import { getGroup, createGroupInvitation, listPeople } from '../../../../../api';

// Alerts
import { setError, setSuccess } from '../../../../../alerts';

// Components
import { BackToButton } from '../../../../../components';

import { enumToDisplayString } from '@ergonauts/ergo-algo-react/core/util';


// Constants
const roles = ['ADMINISTRATOR', 'ERGONAUT_EVALUATOR'];

class CreateGroupInvite extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: null,
      role: '',
      email: '',
      isLoading: false,
      redirect: false,
      emailSuggestions: []
    };
  }

  async componentDidMount() {
    listPeople().then(({ results }) => {
      this.setState({ emailSuggestions: results.map(person => (person.email)) });
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve people');
    });

    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      this.setState({ group: group });
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve group');
    });

    const role = qs.parse(this.props.location?.search, { ignoreQueryPrefix: true })?.role;
    if (role != null) this.setState({ role });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onEmailChange = (_, newEmail) => {
    this.setState({ email: newEmail });
  }

  onSubmit = (e) => {
    e.preventDefault();

    this.setState({
      isLoading: true
    });

    createGroupInvitation({ groupId: this.state.group?.getId(), email: this.state.email, role: this.state.role }).then(() => {
      setSuccess('Successfully sent invitation');
      this.setState({ isLoading: false, redirect: true });
    }).catch(error => {
      setError(error ?? 'Error: unable to create invitation');
      this.setState({ isLoading: false });
    });
  };

  render() {
    return (
      this.state.redirect ? (
        <Redirect to={'/groups/' + this.state.group?.getId() + '/invitations'} />
      ) : (
        <Container style={{ paddingTop: '20px' }}>
          <BackToButton to={'/groups/' + this.state.group?.getId() + '/invitations'} description={this.state.group?.getName() + '\'s Invitations'} />

          <form autoComplete="off" onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>

            {/* ----- Group Info ----- */}
            <Typography variant='h1' style={{ marginBottom: '1em', marginTop: '1em', textAlign: 'center' }}>Create {this.state.group?.getName()} Invitation</Typography>

            <TextField
              required
              select
              name="role"
              label="Role"
              style={{ width: '100%', marginBottom: '10px' }}
              value={this.state.role}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}>
              {roles.map((role) => (
                <MenuItem key={role} value={role}>{enumToDisplayString(role)}</MenuItem>
              ))}
            </TextField>

            <Autocomplete
              freeSolo
              required
              name='email'
              options={this.state.emailSuggestions}
              style={{ width: '100%', marginBottom: '10px' }}
              isOptionEqualToValue={(option, value) => option === value}
              value={this.state.email}
              onChange={this.onEmailChange}
              renderInput={(params) => <TextField required {...params} variant='filled' label='Email' type='email' onChange={this.onChange} name='email' />}
              loadingText="Getting suggestions..."
              disabled={this.state.isLoading}
            />


            <FormHelperText>Must match the email address used for their account.</FormHelperText>

            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
              <Button type='submit' isLoading={this.state.isLoading} style={{ width: '49%' }}>
                Submit
              </Button>
            </div>
          </form>
        </Container>
      )
    );
  }
}

CreateGroupInvite.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

export default withRouter(CreateGroupInvite);
