import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';

// API
import { listGroupEvaluatorAssociations, getGroup, toggleGroupAssociationActive } from '../../../../api';

// Alerts
import { setError } from '../../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../../alerts/confirm';

// Components
import { Button, MaterialTable } from '@lexcelon/react-util';
import { BackToButton } from '../../../../components';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';

const COLUMNS = ({ deactivateAssociation, isLoadingDeactivationForId }) => ([
  {
    title: 'View',
    value: rowData => rowData.getId(),
    render: rowData => (
      <Link to={isLoadingDeactivationForId == null ? ('/people/' + rowData?.getPerson()?.getId()) : '#'}><VisibilityIcon /></Link>
    ),
  },
  {
    title: 'Name',
    value: rowData => rowData?.getPerson()?.getFullName()
  },
  {
    title: 'Email',
    value: rowData => rowData?.getPerson()?.getEmail()
  },
  {
    title: 'Phone Number',
    value: rowData => rowData?.getPerson()?.getPhoneNumber()
  },
  {
    title: 'Is Active in Group',
    value: rowData => rowData?.getIsActive() ? 'Active' : 'Inactive',
  },
  {
    title: 'Is Account Suspended',
    value: rowData => rowData?.getPerson()?.getSuspended() ? 'Suspended' : 'Active',
  },
  {
    title: 'Address',
    value: rowData => rowData?.getPerson()?.getEvaluator()?.getWorkAddress()?.getFullAddress()
  },
  {
    title: 'Remove from Group',
    value: () => null,
    render: rowData => (
      rowData?.getIsActive() ? (
        <Button
          danger
          onClick={() => deactivateAssociation(rowData.getId())}
          isLoading={rowData.getId() === isLoadingDeactivationForId}
          disabled={isLoadingDeactivationForId != null}>
          <CancelIcon />
        </Button>
      ) : null
    ),
  }
]);

class GroupEvaluators extends Component {
  constructor(props) {
    super(props);

    this.state = {
      evaluators: [],
      group: null,
      isLoadingDeactivationForId: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then(group => {
      this.setState({ group: group });
    }).catch(error => {
      setError(error ?? 'Error: Could not load group');
    });

    this.loadGroupEvaluators();
  }

  loadGroupEvaluators = () => {
    const groupId = this.props.match?.params?.id;
    this.setState({ isLoading: true });
    listGroupEvaluatorAssociations({ groupId }).then(({ results }) => {
      this.setState({
        evaluators: results,
        isLoading: false
      });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve evaluators');
      this.setState({ isLoading: false });
    });
  }

  deactivateAssociation = (associationId) => {
    confirm({
      title: 'Are you sure you want to remove this evaluator from the group?',
      body: 'Any scheduled or confirmed evaluations will be cancelled and any evaluees will be notified. You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        const groupId = this.props.match?.params?.id;
        this.setState({ isLoadingDeactivationForId: associationId });
        toggleGroupAssociationActive({ groupId, associationId, isActive: false }).then(() => {
          this.setState({ isLoadingDeactivationForId: null });
          this.loadGroupEvaluators();
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to remove association.');
          stopConfirmLoading();
        });
      },
      danger: true
    });
  }

  render() {
    return (
      <>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/groups/' + this.state.group?.getId()} description={this.state.group?.getName()} />
        </div>
        <div style={{ margin: '2em' }}>
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>{this.state.group?.getName()} Evaluators</Typography>
          <MaterialTable
            title={
              <Button component={Link} to={'/groups/' + this.props.match?.params?.id + '/invitations/create?role=ERGONAUT_EVALUATOR'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
                Invite New
                <AddIcon style={{ marginLeft: '5px' }} />
              </Button>
            }
            data={{
              mode: 'list',
              rows: this.state.evaluators,
              columns: COLUMNS({ deactivateAssociation: this.deactivateAssociation, isLoadingDeactivationForId: this.state.isLoadingDeactivationForId })
            }}
            options={{
              pageSize: 20
            }}
            isLoading={this.state.isLoading}
          />
        </div>
      </>
    );
  }
}

GroupEvaluators.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(GroupEvaluators);
