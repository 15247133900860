import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import { isEmail, isValidPhoneNumber } from '@lexcelon/js-util/lib/validator';
import ReactPhoneInput from 'react-phone-input-material-ui';

// Alerts
import { setSuccess, setError, clearErrors } from '../alerts';

// Date/Time UI
import { DateTime } from 'luxon';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { getLocaleTimeZone } from '@ergonauts/ergo-algo-react/core/constants';

// Components
import { FormControlLabel, MenuItem, Radio, RadioGroup, TextField, Typography, FormHelperText } from '@mui/material';
import { Button, LineItem } from '@lexcelon/react-util';
import AddressInput from './AddressInput';

// API
import { getPerson, listGroups, updateEvaluation, scheduleEvaluation, getGroup, listGroupEvaluatorAssociations, listEvaluatorEligibleSegments } from '../api';

class EvaluationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      associations: [],
      groupId: this.props.groupId != null ? this.props.groupId : '',
      evaluatorId: this.props.evaluatorId != null ? this.props.evaluatorId : '',
      address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
      },
      evalueeCanViewReport: 'true',
      group: null,
      segmentId: '',
      dateTime: null,
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      formError: false,
      isLoading: false,
      groups: [],
      evaluators: [],
      segments: [],
      redirect: false,
      redirectToEvaluationId: null,
      datePickerOpen: false
    };
  }

  componentDidMount() {
    if (this.props.evaluation != null) {
      this.setState({
        groupId: this.props.evaluation.getGroup()?.getId() || '',
        evaluatorId: this.props.evaluation.getEvaluator().getId() || '',
        segmentId: this.props.evaluation.getSegment()?.getId() || '',
        dateTime: this.props.evaluation.getScheduledDateTime() || '',
        firstName: this.props.evaluation.getEvaluee()?.getFirstName() || '',
        lastName: this.props.evaluation.getEvaluee()?.getLastName() || '',
        email: this.props.evaluation.getEvaluee()?.getEmail() || '',
        phoneNumber: this.props.evaluation.getEvaluee()?.getPhoneNumber() || '',
        evalueeCanViewReport: this.props.evaluation.getEvalueeCanViewReport() === true ? 'true' : 'false',
        address: {
          line1: this.props.evaluation.getAddress()?.getLine1() ?? '',
          line2: this.props.evaluation.getAddress()?.getLine2() ?? '',
          city: this.props.evaluation.getAddress()?.getCity() ?? '',
          state: this.props.evaluation.getAddress()?.getState() ?? '',
          zip: this.props.evaluation.getAddress()?.getZip() ?? ''
        }
      });
    }

    // If Group Id was passed, retrieve Group
    if (this.props.groupId != null) {
      getGroup(this.props.groupId).then(group => {
        this.setState({ group }, () => {
          if (this.props.evaluatorId == null) this.retrieveGroupEvaluators();
          else this.retrieveEvaluatorEligibleSegments();
        });
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve group.');
      });
    }

    // If Evaluator Id was passed, retrieve Evaluator
    if (this.props.evaluatorId != null) {
      // TODO: Make sure the evaluator is part of the selected group if group was also passed

      getPerson(this.props.evaluatorId).then(evaluator => {
        this.setState({ evaluator }, () => {
          if (this.props.groupId == null) this.retrieveEvaluatorGroups();
          else this.retrieveEvaluatorEligibleSegments();
        });
      }).catch(error => {
        setError(error ?? 'Error: Unable to retrieve evaluator.');
      });
    }

    else if (this.props.groupId == null) {
      // Otherwise, retrieve list of groups for selection
      this.retrieveGroups();
    }
  }

  componentWillUnmount() {
    clearErrors();
  }

  onError() {
    if (this.props.onClose != null) this.props.onClose();
    else this.setState({ redirect: true });
  }

  retrieveGroups() {
    listGroups().then(({ results }) => {
      this.setState({ groups: results }, () => {
        if (this.props.evaluation != null) this.handleGroupChange(this.props.evaluation?.getGroup());
      });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve list of groups.');
    });
  }

  retrieveEvaluatorGroups() {
    getPerson(this.state.evaluatorId).then(person => {
      let groups = [];
      person.getGroupAssociations()?.forEach(association => {
        // Add groups for which the person is an evaluator
        if (groups.find(group => group.getId() === association.getGroup()?.getId()) == null && association.getRole().getRole() === 'ERGONAUT_EVALUATOR') {
          groups.push(association.getGroup());
        }
      });
      this.setState({ groups });
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve list of groups');
    });
  }

  retrieveGroupEvaluators() {
    listGroupEvaluatorAssociations({ groupId: this.state.groupId }).then(({ results }) => {
      let evaluators = [];
      results.forEach(association => {
        evaluators.push(association.getPerson());
      });

      this.setState({ evaluators }, () => {
        if (this.props.evaluation != null) this.handleEvaluatorChange(this.props.evaluation?.getEvaluator());
      });
    }).catch(error => {
      setError(error ?? 'Error: Could not retrieve group evaluators');
    });
  }

  retrieveEvaluatorEligibleSegments() {
    listEvaluatorEligibleSegments({ evaluatorId: this.state.evaluatorId, groupId: this.state.groupId }).then(({ results }) => {
      if (results.length == 0) {
        results = [{ name:'No Certifications', id: 0 }];
      }
      this.setState({ segments: results });
    }).catch(error => {
      setError(error ?? 'Error: could not retrieve eligible segments');
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleGroupChange(value) {
    const groupId = value?.getId();

    let evaluatorId = this.props.evaluatorId != null ? this.props.evaluatorId : '';
    this.setState({
      groupId,
      evaluatorId,
      evaluators: [],
      segmentId: this.props.evaluation != null ? this.state.segmentId : '',
      segments: []
    }, () => {
      if (this.props.evaluatorId == null) this.retrieveGroupEvaluators();
      else this.retrieveEvaluatorEligibleSegments();
    });
  }

  handleEvaluatorChange(value) {
    this.setState({
      evaluatorId: value?.getId(),
      segmentId: this.props.evaluation != null ? this.state.segmentId : '',
      segments: []
    }, () => {
      this.retrieveEvaluatorEligibleSegments();
    });
  }


  hasValidForm = () => {
    if (this.state.dateTime < DateTime.now()) {
      setError('Error: Please pick a future date and time.');
      return false;
    }

    if (this.state.dateTime?.invalid) {
      setError('Error: Please pick a valid date and time.');
      return false;
    }

    if (this.state.firstName?.length > 255 || this.state.lastName?.length > 255) {
      setError('Error: Please enter a name of less than 255 characters.');
      return false;
    }

    if (!isEmail(this.state.email)) {
      setError('Error: Invalid email');
      return false;
    }

    if (!isValidPhoneNumber(this.state.phoneNumber)) {
      setError('Error: Invalid phone number');
      return false;
    }

    if (this.state.address.line1 == '' || this.state.address.city == '' || this.state.address.state == '' || this.state.address.zip == '') {
      setError('Error: Invalid address');
      return false;
    }

    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    if (!this.hasValidForm()) return;

    this.setState({ isLoading: true });

    const {
      address,
      segmentId,
      groupId,
      evaluatorId,
      dateTime,
      firstName,
      lastName,
      email,
      phoneNumber,
      evalueeCanViewReport
    } = this.state;

    let evaluee = { firstName, lastName, email, phoneNumber };

    if (this.props.evaluation == null) {
      scheduleEvaluation({
        groupId,
        evaluatorId,
        segmentId,
        scheduledDateTime: dateTime?.ts,
        evaluee,
        address,
        evalueeCanViewReport
      }).then((evaluation) => {
        setSuccess('Successfully scheduled evaluation. Evaluee has received an invitation link via email. They must accept the invitation before you may begin the evaluation.');
        this.setState({ isLoading: false });
        if (this.props.onSuccess != null) this.props.onSuccess(evaluation);
        else this.setState({ redirectToEvaluationId: evaluation.getId() });
      }).catch((error) => {
        setError(error ?? 'Error: Unable to schedule evaluation. Please try again');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateEvaluation({
        id: this.props.evaluation?.getId(),
        evaluatorId,
        scheduledDateTime: dateTime?.ts,
        evaluee,
        address,
        segmentId,
        evalueeCanViewReport
      }).then((evaluation) => {
        setSuccess('Successfully updated evaluation.');
        this.setState({ isLoading: false });
        if (this.props.onSuccess != null) this.props.onSuccess(evaluation);
        else this.setState({ redirectToEvaluationId: this.props.evaluation.getId() });
      }).catch((error) => {
        setError(error ?? 'Error: Unable to update evaluation. Please try again');
        this.setState({ isLoading: false });
      });
    }
  };

  formChanged() {
    return !(this.props.evaluation.getEvaluator().getId() === this.state.evaluatorId &&
    this.props.evaluation.getSegment()?.getId() === this.state.segmentId &&
    this.props.evaluation.getScheduledDateTime() === this.state.dateTime &&
    this.props.evaluation.getEvaluee()?.getFirstName() === this.state.firstName &&
    this.props.evaluation.getEvaluee()?.getLastName() === this.state.lastName &&
    this.props.evaluation.getEvaluee()?.getEmail() === this.state.email &&
    this.props.evaluation.getEvaluee()?.getPhoneNumber() === this.state.phoneNumber &&
    this.props.evaluation.getAddress()?.getLine1() === this.state.address?.line1 &&
    ((this.props.evaluation.getAddress()?.getLine2() === null && this.state.address?.line2 === '') || (this.props.evaluation.getAddress()?.getLine2() == this.state.address?.line2)) &&
    this.props.evaluation.getAddress()?.getCity() === this.state.address?.city &&
    this.props.evaluation.getAddress()?.getState() === this.state.address?.state &&
    this.props.evaluation.getAddress()?.getZip() == this.state.address?.zip &&
    this.props.evaluation.getEvalueeCanViewReport().toString() === this.state.evalueeCanViewReport);
  }

  render() {
    if (this.state.redirect) return <Redirect to='/' />;
    else if (this.state.redirectToEvaluationId != null) return <Redirect to={'/evaluations/' + this.state.redirectToEvaluationId} />;
    return (
      <form autoComplete='off' onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>
        {/* Group */}
        {this.props.groupId != null || this.props.evaluation != null ? (
          (this.state.group != null || this.props.evaluation?.getGroup() != null) &&
          <LineItem
            value={this.state.group != null ? this.state.group.getName() : this.props.evaluation.getGroup().getName()}
            description='Group'
          />
        ) : (

          <Autocomplete
            getOptionLabel={(option => option?.getName())}
            name='groupId'
            options={this.state.groups}
            style={{ width: '100%', marginBottom: '10px' }}
            isOptionEqualToValue={(option, value) => option.getId() === value.getId()}
            value={this.state.groups?.find(group => group.getId() === this.state.groupId) || null}
            onChange={(e, value) => this.handleGroupChange(value)}
            renderInput={(params) => <TextField required {...params} variant='filled' label='Group' />}
            disabled={this.state.isLoading}
          />
        )}

        {/* Evaluator */}
        {this.props.evaluatorId != null ? (
          (this.state.evaluator != null &&
          <LineItem
            value={this.state.evaluator.getFullName()}
            description='Evaluator'
          />)
        ) : (
          <Autocomplete
            getOptionLabel={(option =>  option?.getFullName() + ' (' + option?.getEmail() + ')')}
            name='evaluatorId'
            options={this.state.evaluators}
            style={{ width: '100%', marginBottom: '10px' }}
            isOptionEqualToValue={(option, value) => option.getId() === value.getId()}
            value={this.state.evaluators?.find(evaluator => evaluator.getId() === this.state.evaluatorId) || null}
            onChange={(event, value) => this.handleEvaluatorChange(value)}
            renderInput={(params) => <TextField required {...params} variant='filled' label='Evaluator' />}
            disabled={this.state.isLoading || this.state.groupId === ''}
          />
        )}

        {/* Evaluation Type */}
        <TextField
          required
          select
          name='segmentId'
          label='Evaluation Type'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.segmentId}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading || this.state.evaluatorId === '' || this.state.groupId === ''}>
          {this.state.segments?.map((segment) => (
            <MenuItem key={segment.id} value={segment.id} disabled={segment.name === 'No Certifications'}>{segment.name}</MenuItem>
          ))}
        </TextField>

        {/* Evaluee Info */}
        <>
          <Typography variant='h2' style={{ marginTop: '1em', marginBottom: '0.5em' }}>Evaluee</Typography>

          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <TextField
              required
              error={this.state.firstName?.length > 255}
              name='firstName'
              label='Evaluee First Name'
              type='text'
              style={{ width: '49%', marginBottom: '10px' }}
              value={this.state.firstName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
            />

            <TextField
              required
              error={this.state.lastName?.length > 255}
              name='lastName'
              label='Evaluee Last Name'
              type='text'
              style={{ width: '49%', marginBottom: '10px' }}
              value={this.state.lastName}
              onChange={this.onChange}
              variant='filled'
              disabled={this.state.isLoading}
            />
          </div>

          <TextField
            required
            name='email'
            label='Evaluee Email'
            type='text'
            style={{ width: '100%' }}
            value={this.state.email}
            onChange={this.onChange}
            variant='filled'
            disabled={this.state.isLoading}
          />
          <FormHelperText style={{ marginBottom: '10px' }}>If the evaluee already has an account, please make sure to use the same email as that on their account.</FormHelperText>

          <ReactPhoneInput
            country='us'
            onlyCountries={['us']}
            value={this.state.phoneNumber}
            onChange={(phoneNumber) => this.setState({ phoneNumber })}
            component={TextField}
            inputProps={{ variant: 'filled', required: true, label: 'Evaluee Phone Number', disabled: this.state.isLoading }}
            disableCountryCode={true}
            disableDropdown={true}
            placeholder='(702) 123-4567'
            containerStyle={{ width: '100%', marginBottom: '10px' }}
          />

        </>

        {/* ----- EVALUATION ADDRESS ----- */}
        <>
          <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Evaluation Address</Typography>

          <AddressInput
            isLoading={this.state.isLoading}
            onChange={(address) => this.setState({ address })}
            requiredFields={['line1', 'city', 'state', 'zip']}
            initialValue={this.props.evaluation != null ? {
              line1: this.props.evaluation.getAddress()?.getLine1() ?? '',
              line2: this.props.evaluation.getAddress()?.getLine2() ?? '',
              city: this.props.evaluation.getAddress()?.getCity() ?? '',
              state: this.props.evaluation.getAddress()?.getState() ?? '',
              zip: String(this.props.evaluation.getAddress()?.getZip()) ?? '',
            } : undefined}
          />
        </>

        <>
          <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Evaluation Date/Time</Typography>
          {/* Evaluation Date/Time */}
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <DateTimePicker
                minDateTime={DateTime.now()}
                renderInput={(props) =>
                  <TextField
                    required
                    variant='filled'
                    style={{ width: '100%' }}
                    {...props}
                    error={this.state.dateTime != null && !this.state.dateTime.isValid}
                    onClick={() => this.setState({ datePickerOpen: true })}
                  />
                }
                open={this.state.datePickerOpen && !this.state.isLoading}
                name='dateTime'
                label='Evaluation Date/Time'
                value={this.state.dateTime}
                onChange={(newValue) => {
                  this.setState({
                    dateTime: newValue,
                    formError: false
                  });
                }}
                onClose={() => this.setState({ datePickerOpen: false })}
                disabled={this.state.isLoading}
                // Needed because of https://stackoverflow.com/questions/70106353/material-ui-date-time-picker-safari-browser-issue
                onViewChange={() => {
                  setTimeout(() => {
                    const el = document.activeElement;
                    if (el) {
                      el.blur();
                    }
                  });
                }}
              />
              <Typography variant='body1' style={{ fontSize: '20px', fontWeight: 'bold', color: '#404040', marginLeft: '8px' }}>{getLocaleTimeZone()}</Typography>
            </div>
          </LocalizationProvider>
          <FormHelperText style={{ marginBottom: '10px' }}>Please note date/time above is scheduled in your current local time.</FormHelperText>
        </>

        <>
          {((this.state.group != null && this.state.group.getEvalueeCanViewReport()) || this.state.groups?.find(group => group.getId() === this.state.groupId)?.getEvalueeCanViewReport()) &&
          <div>
            <Typography variant='h2' style={{ marginBottom: '0.5em', marginTop: '1em' }}>Prevent Evaluee From Viewing Evaluation Report?</Typography>
            <RadioGroup
              defaultValue={this.state.evalueeCanViewReport}
              name='evalueeCanViewReport'
              onChange={this.onChange}>
              <FormControlLabel disabled={this.state.isLoading} value='false' control={<Radio />} label='Yes' />
              <FormControlLabel disabled={this.state.isLoading} value='true' control={<Radio />} label='No' />
            </RadioGroup>
          </div>}
        </>

        {/* Form Buttons */}
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', marginTop: '40px' }}>
          {this.props.onClose != null &&
          <Button secondary disabled={this.state.isLoading} style={{ width: '49%', marginRight: '10px' }} onClick={this.props.onClose}>
            Cancel
          </Button>}
          <Button type='submit' style={{ width: '49%' }} isLoading={this.state.isLoading} disabled={this.props.evaluation != null && !this.formChanged()}>Submit</Button>
        </div>
      </form>
    );
  }
}

EvaluationForm.propTypes = {
  match: PropTypes.any,
  location: PropTypes.object.isRequired,
  onSuccess: PropTypes.func.isRequired,
  evaluatorId: PropTypes.number,
  evaluation: PropTypes.object,
  onClose: PropTypes.func,
  groupId: PropTypes.number
};

export default withRouter(EvaluationForm);
