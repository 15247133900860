import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// Icons
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';

// API
import { listTrainingModules } from '../../../api';

// Alerts
import { setError } from '../../../alerts';

const COLUMNS = [
  {
    title: 'View',
    value: rowData => rowData.getId(),
    render: rowData => (
      <Link to={'/training/training-modules/' + rowData.getId()}><VisibilityIcon /></Link>
    ),
  },
  {
    value: () => null,
    title: 'Display Image',
    render: rowData => rowData.getDisplayImageUrl() && <img src={rowData.getDisplayImageUrl()} style={{ width: '100px', height: 'auto' }} />
  },
  {
    value: rowData => rowData.getName(),
    title: 'Name'
  },
  {
    value: rowData => rowData.getIsActive(),
    title: 'Is Active',
    customFilterAndSearch: (term, rowData) => (rowData.isActive && term?.toLowerCase() === 'active') || (!rowData.isActive && term?.toLowerCase() === 'not active')
  },
  {
    title: 'Segment',
    value: () => null,
    customFilterAndSearch: (term, rowData) => rowData.getSegment()?.getName().toLowerCase()?.includes(term?.toLowerCase()),
    render: rowData => (
      rowData.getSegment()?.getName() ? rowData.getSegment()?.getName() : ''
    )
  }
];

class TrainingModules extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trainingModules: [],
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listTrainingModules().then(({ results }) => {
      this.setState({ trainingModules: results, isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve training modules.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Training Modules</Typography>
        <MaterialTable
          title={
            <Button component={Link} to='/training/training-modules/create' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
              Create New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'list',
            rows: this.state.trainingModules,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

export default TrainingModules;
