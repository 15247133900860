import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Button, MaterialTable } from '@lexcelon/react-util';

// API
import { listGroups } from '../../api';

// Alerts
import { setError } from '../../alerts';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddIcon from '@mui/icons-material/Add';

const COLUMNS = [
  {
    title: 'View',
    value: rowData => rowData.getId(),
    render: rowData => (
      <Link to={'/groups/' + rowData?.getId()}><VisibilityIcon /></Link>
    ),
  },
  {
    title: 'Logo',
    value: () => null,
    render: rowData => rowData?.getLogoUrl() && <img src={rowData?.getLogoUrl()} style={{ width: '100px', height: 'auto' }} />
  },
  {
    value: rowData => rowData.getName(),
    title: 'Name',
  },
  {
    value: rowData => rowData?.getIsActive(),
    title: 'Status',
    render: rowData => rowData?.getIsActive() ? 'Active' : 'Inactive'
  },
  {
    value: rowData => rowData.getEvaluationCount(),
    title: 'Number of Evaluations',
    customFilterAndSearch: (term, rowData) => rowData.getEvaluationCount() == term
  },
  {
    value: rowData => rowData.getEvaluatorCount(),
    title: 'Number of Evaluators',
    customFilterAndSearch: (term, rowData) => rowData.getEvaluatorCount() == term
  },
  {
    value: rowData => rowData.getAdminCount(),
    title: 'Number of Admins',
    customFilterAndSearch: (term, rowData) => rowData.getAdminCount() == term
  },
  {
    title: 'Contact Name',
    value: rowData => rowData?.getContactName(),
    customFilterAndSearch: (term, rowData) => rowData?.getContactName()?.toLowerCase()?.includes(term?.toLowerCase()),
    customSort: (a, b) => a.getContactName()?.localeCompare(b.getContactName()),
  },
  {
    title: 'Contact Phone Number',
    value: rowData => rowData?.getContactPhoneNumber(),
    customSort: (a, b) => a.getContactPhoneNumber()?.localeCompare(b.getContactPhoneNumber()),
  },
  {
    title: 'Contact Email',
    value: rowData => rowData?.getContactEmail(),
    customSort: (a, b) => a.getContactEmail()?.localeCompare(b.getContactEmail()),
  },
];

class Groups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      person: null,
      isLoading: false
    };
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    listGroups().then(({ results }) => {
      this.setState({ groups: results, isLoading: false });
    }).catch(error => {
      setError(error ?? 'Error: Unable to retrieve groups');
      this.setState({ isLoading: false });
    });
  }

  render() {
    return (
      <div style={{ margin: '2em' }}>
        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Groups</Typography>
        <MaterialTable
          title={
            <Button component={Link} to='/groups/create' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '5px 0px 5px 0px', width: '200px' }}>
              Create New
              <AddIcon style={{ marginLeft: '5px' }} />
            </Button>
          }
          data={{
            mode: 'list',
            rows: this.state.groups,
            columns: COLUMNS
          }}
          options={{
            pageSize: 20
          }}
          isLoading={this.state.isLoading}
        />
      </div>
    );
  }
}

Groups.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(Groups);
