import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Avatar, Checkbox, MenuItem, FormControlLabel, FormHelperText, TextField, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import MultipleSelectChip from './MultipleSelectChip';

// Alerts
import { setError, setSuccess } from '../alerts';

// API
import { createErgonar, listSegments, updateErgonar } from '../api';
import { MAX_FILE_SIZE_MB } from '@ergonauts/ergo-algo-react/core/constants';
import { DateTime } from 'luxon';

// Constants
import { getLocaleTimeZone } from '@ergonauts/ergo-algo-react/core/constants';
const ZOOM = 'ZOOM';
const VIDEO = 'VIDEO';

class ErgonarForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
      name: '',
      price: '',
      notes: '',
      description: '',
      segments: [],
      dateTime: null,
      estimatedDuration: '',
      zoomWebinarId: '',
      videoUrl: '',
      displayImagePath: '',
      isActive: true,
      formError: false,
      isLoading: true,
      type: '',
      datePickerOpen: false
    };
  }

  // If ergonar is passed in we should populate our state with its values.
  componentDidMount() {
    if (this.props.ergonar != null) {
      this.setState({
        id: this.props.ergonar.id,
        name: this.props.ergonar.name ?? '',
        price: this.props.ergonar.price ?? '',
        segments: this.props.ergonar.segments ?? [],
        description: this.props.ergonar.description ?? '',
        notes: this.props.ergonar.notes ?? '',
        dateTime: this.props.ergonar.dateTime != null ? this.props.ergonar.dateTime : null,
        estimatedDuration: this.props.ergonar.estimatedDuration ?? '',
        zoomWebinarId: this.props.ergonar.zoomWebinarId ?? '',
        videoUrl: this.props.ergonar.videoUrl ?? '',
        isActive: this.props.ergonar.isActive,
        type: this.props.ergonar.getType() ?? ''
      });
    }

    listSegments().then(({ results }) => {
      this.setState({
        segmentList: results,
        isLoading: false
      });
    }).catch(error => {
      setError(error ?? 'Error loading segments, please try again.');
    });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

    this.setState({ formError: false });
  };

  onCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked });
  };

  // When select box for segment changes, get the Ids from the names, save to state.
  onSegmentChange = (values) => {
    var segmentIds = [];

    for (var i = 0; i < values.length; i++) {
      var segmentName = values[i];
      var index = this.state.segmentList.findIndex(elem => elem.name == segmentName);

      segmentIds.push(this.state.segmentList[index]);
    }

    this.setState({ segments: segmentIds });
  };

  hasValidForm = () => {
    // These fields must be provided every time.
    if (this.state.name == '' || this.state.price == '' || this.state.description == '') {
      setError('Error: You must include the ergonar name, price, and description.');
      return false;
    }

    if (this.state.description?.length > 2040) {
      setError('Error: Please use a description with 2040 characters or less');
      return false;
    }

    if (this.state.dateTime?.invalid) {
      setError('Error: Please pick a valid date and time.');
      return false;
    }

    // Future ergonar case
    if (this.state.dateTime != null && this.state.estimatedDuration != '' && this.state.zoomWebinarId != '') {
      return true;
    }

    // Future ergonar case
    if (this.state.dateTime != null || this.state.estimatedDuration != '' || this.state.zoomWebinarId != '') {
      setError('Error: All of the future ergonar details must be filled in.');
      return false;
    }

    // Previous ergonar case
    if (this.state.videoUrl != '') {
      return true;
    }

    // This is only possible if they only entered name, and price.
    setError('Error: You must provide future or previous ergonar details.');
    return false;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const validForm = this.hasValidForm();

    this.setState({
      formError: !validForm,
      isLoading:true
    });

    if (!validForm) {
      this.setState({ isLoading: false });
      return;
    }

    // Put together body
    var form = {
      name: this.state.name,
      price: this.state.price,
      notes: this.state.notes,
      description: this.state.description,
      isActive: this.state.isActive,
      type: this.state.type,
      displayImage: this.state.displayImage
    };
    if (this.state.id != null) form.id = this.state.id;
    if (this.state.type === VIDEO) form.videoUrl = this.state.videoUrl;
    else {
      form.dateTime = this.state.dateTime;
      form.estimatedDuration = this.state.estimatedDuration;
      form.zoomWebinarId = this.state.zoomWebinarId;
    }
    form.segments = this.state.segments?.map((elem) => elem.id) ?? [];

    if (this.props.ergonar == null) {
      createErgonar(form).then((ergonar) => {
        this.setState({ isLoading: false });
        setSuccess('Successfully created an Ergonar!');
        this.props.onSuccess(ergonar);
      }).catch((error) => {
        setError(error ?? 'Error: Unable to complete request, please try again.');
        this.setState({ isLoading: false });
      });
    }
    else {
      updateErgonar(form).then((ergonar) => {
        this.setState({ isLoading: false });
        setSuccess('Successfully updated the Ergonar!');
        this.props.onSuccess(ergonar);
      }).catch((error) => {
        setError(error ?? 'Error: Unable to complete request, please try again.');
        this.setState({ isLoading: false });
      });
    }
  };

  onImageInput = (e) => {
    // Set Image for Display
    var input = document.getElementById('displayImagePath');
    // get file size in megabytes, throw error if file is too large
    var size = input.files[0].size / 1e6;
    if (size >= MAX_FILE_SIZE_MB) {
      setError(`Error: Please select a file smaller than ${MAX_FILE_SIZE_MB}MB.`);
      this.setState({ displayImagePath: '' });
      return;
    }

    this.setState({ [e.target.name]: e.target.value });

    var fReader = new FileReader();
    fReader.readAsDataURL(input.files[0]);
    this.setState({ displayImage: input.files[0] });
    fReader.onloadend = function (event) {
      document.getElementById('displayImage').innerHTML = '<image src=\'' + event.target.result + '\' style=\'height: 60px;\'/>';
    };
  };

  formChanged() {
    return !(this.props.ergonar.name === this.state.name &&
    this.props.ergonar.price == this.state.price &&
    this.props.ergonar.segments === this.state.segments &&
    this.props.ergonar.description === this.state.description &&
    ((this.props.ergonar.notes === null && this.state.notes === '') || (this.props.ergonar.notes == this.state.notes)) &&
    this.props.ergonar.dateTime === this.state.dateTime &&
    ((this.props.ergonar.estimatedDuration == this.state.estimatedDuration) || (this.props.ergonar.estimatedDuration === null && this.state.estimatedDuration === '')) &&
    ((this.props.ergonar.zoomWebinarId == this.state.zoomWebinarId) || (this.props.ergonar.zoomWebinarId === null && this.state.zoomWebinarId === '')) &&
    ((this.props.ergonar.videoUrl == this.state.videoUrl) || (this.props.ergonar.videoUrl === null && this.state.videoUrl === '')) &&
    this.props.ergonar.isActive === this.state.isActive &&
    this.props.ergonar.getType() === this.state.type &&
    this.state.displayImagePath === '' );
  }

  render() {
    return (
      <form autoComplete='off' onSubmit={this.onSubmit} style={{ marginBottom: '5em' }}>

        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: '1em', marginBottom: '1em' }}>
          <input disabled={this.state.isLoading} type='file' accept='.png,.jpg,.jpeg' id='displayImagePath' name='displayImagePath' ref={ref=> this.fileInput = ref} label='Select Image' onChange={this.onImageInput} style={{ width: '60%' }} value={this.state.displayImagePath} />
          <div id='displayImage'>
            <Avatar />
          </div>
        </div>

        <TextField
          required
          error={this.state.formError && this.state.name === ''}
          name='name'
          label='Name'
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.name}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <TextField
          required
          error={this.state.formError && this.state.price === ''}
          name='price'
          label='Price'
          type='number'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.price}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <MultipleSelectChip
          label={'ErgoAlgo™ Segment'}
          values={this.state.segmentList && this.state.segmentList.map((segment) => segment.name)}
          onUpdate={this.onSegmentChange}
          defaultValues={this.state?.segments.map((elem) => elem?.name)}
          disabled={this.state.isLoading}
        />

        <TextField
          required
          error={this.state.formError && this.state.description === ''}
          name='description'
          label='Description'
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          multiline='true'
          value={this.state.description}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <TextField
          name='notes'
          label='Notes'
          type='text'
          style={{ width: '100%', marginBottom: '10px' }}
          value={this.state.notes}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}
        />

        <FormControlLabel
          control={
            <Checkbox
              disabled={this.state.isLoading}
              checked={this.state.isActive}
              onChange={this.onCheckboxChange}
              name='isActive'
            />
          }
          label='Set Active'
        />

        <TextField
          required
          select
          name='type'
          label='Ergonar Type'
          style={{ width: '100%', marginTop: '3em' }}
          value={this.state.type}
          onChange={this.onChange}
          variant='filled'
          disabled={this.state.isLoading}>
          <MenuItem value={ZOOM}>
            Zoom
          </MenuItem>
          <MenuItem value={VIDEO}>
            Video
          </MenuItem>
        </TextField>


        {/* ----- FUTURE ERGONAR ----- */}
        {this.state.type === ZOOM &&
        <>
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Zoom Ergonar</Typography>

          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <DateTimePicker
                minDateTime={DateTime.now()}
                renderInput={(props) =>
                  <TextField
                    variant='filled'
                    style={{ width: '100%' }} {...props}
                    error={this.state.dateTime != null && !this.state.dateTime.isValid}
                    required={this.state.type === ZOOM}
                    onClick={() => this.setState({ datePickerOpen: true })}
                  />
                }
                open={this.state.datePickerOpen && !this.state.isLoading}
                name='dateTime'
                label='Date/Time'
                value={this.state.dateTime}
                onClose={() => this.setState({ datePickerOpen: false })}
                onChange={(newValue) => {
                  this.setState({
                    dateTime: newValue,
                    formError: false
                  });
                }}
                disabled={this.state.isLoading}
                // Needed because of https://stackoverflow.com/questions/70106353/material-ui-date-time-picker-safari-browser-issue
                onViewChange={() => {
                  setTimeout(() => {
                    const el = document.activeElement;
                    if (el) {
                      el.blur();
                    }
                  });
                }}
              />
              <Typography variant='body1' style={{ fontSize: '20px', fontWeight: 'bold', color: '#404040', marginLeft: '8px' }}>{getLocaleTimeZone()}</Typography>
            </div>
          </LocalizationProvider>
          <FormHelperText style={{ marginBottom: '10px' }}>Please note date/time above is scheduled in your current local time.</FormHelperText>

          {/* Estimated Duration */}
          <TextField
            error={this.state.formError && this.state.estimatedDuration === ''}
            disabled={this.state.isLoading}
            name='estimatedDuration'
            label='Estimated Duration (in minutes)'
            type='number'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.estimatedDuration}
            onChange={this.onChange}
            variant='filled'
            inputProps={{ min: 0 }}
            required={this.state.type === ZOOM}
          />

          {/* Zoom Webinar Id */}
          <TextField
            error={this.state.formError && this.state.zoomWebinarId === ''}
            disabled={this.state.isLoading}
            name='zoomWebinarId'
            label='Zoom Webinar Id'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.zoomWebinarId}
            onChange={this.onChange}
            variant='filled'
            required={this.state.type === ZOOM}
          />
        </>}

        {/* ----- PREVIOUS ERGONAR ----- */}
        {this.state.type === VIDEO &&
        <>
          <Typography variant='h2' style={{ marginBottom: '10px', marginTop: '20px' }}>Video Ergonar</Typography>

          {/* Line 2 */}
          <TextField
            error={this.state.formError && this.state.videoUrl === ''}
            disabled={this.state.isLoading}
            name='videoUrl'
            label='Video URL'
            style={{ width: '100%', marginBottom: '10px' }}
            value={this.state.videoUrl}
            onChange={this.onChange}
            variant='filled'
            required={this.state.type === VIDEO}
          />
        </>}

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '40px' }}>
          {this.props.onClose != null &&
          <Button secondary disabled={this.state.isLoading} style={{ width: '100%', marginRight: '10px' }} onClick={this.props.onClose}>
            Cancel
          </Button>}

          <Button type='submit' isLoading={this.state.isLoading} disabled={this.state.error || (this.props.ergonar != null && !this.formChanged())} style={{ width: '100%' }}>
            Submit
          </Button>
        </div>
      </form>
    );
  }
}

ErgonarForm.propTypes = {
  ergonar: PropTypes.object,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func
};

export default ErgonarForm;
