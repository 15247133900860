import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { BackToButton, EvaluatorCertificationTable } from '../../../../../components';

// API
import { getPerson, listEvaluatorCertifications } from '../../../../../api';

// Alerts
import { setError } from '../../../../../alerts';

class PersonCertifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      certifications: [],
      person: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const personId = this.props.match?.params?.id;

    // Get person's info
    getPerson(personId).then(person => {
      this.setState({ person: person });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve person.');
    });

    // Get certifications
    this.setState({ isLoading: true });
    listEvaluatorCertifications({ evaluatorId: personId }).then(({ results }) => {
      if (results != null) {
        this.setState({ certifications: results });
      }
      this.setState({ isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve certifications.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    const personId = this.props.match?.params?.id;
    return (
      <>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/people/' + personId} description={this.state.person?.getFullName()} />
        </div>
        <EvaluatorCertificationTable certifications={this.state.certifications} isLoading={this.state.isLoading} />
      </>
    );
  }
}

PersonCertifications.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(PersonCertifications);
