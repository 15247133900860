import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { AppBar } from '@mui/material';
import { WhiteLogo } from '@ergonauts/ergo-algo-react/core/images';
import { ERGO_BLUE } from '@ergonauts/ergo-algo-react/core/constants';

// Redux
import { connect } from 'react-redux';

class Footer extends Component {
  render() {
    return (
      <AppBar position='absolute' style={{ position: 'absolute', height: '100px', top: 'auto', bottom: 0, backgroundColor: ERGO_BLUE, marginTop: '20px', padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={WhiteLogo} style={{ width: 'auto', height: '60px', margin: '15px' }} />
      </AppBar>
    );
  }
}

Footer.propTypes = {
  personId: PropTypes.number,
  isAdmin: PropTypes.bool
};

const mapStateToProps = (state) => ({
  personId: state.auth.personId,
  isAdmin: state.auth.isAdmin
});

export default connect(mapStateToProps, null)(Footer);
