import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Container, Typography } from '@mui/material';

// Components
import { BackToButton, GroupForm } from '../../../components';

class CreateGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToGroup: false,
      groupId: null,
    };
  }

  onSuccess = (group) => {
    this.setState({
      redirectToGroup: true,
      groupId: group.id
    });
  }

  render() {
    return this.state.redirectToGroup ? <Redirect to={'/groups/' + this.state.groupId} /> : (
      <Container>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/groups'} description='Groups' />
        </div>

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Create Group</Typography>

        <GroupForm
          onSubmit={this.onSubmit}
          onSuccess={this.onSuccess}
        />
      </Container>
    );
  }
}

export default CreateGroup;
