import React, { Component } from 'react';
import { Container, Typography } from '@mui/material';
import { Redirect } from 'react-router';

// Alerts
import { setSuccess } from '../../../../alerts';

// Components
import { BackToButton, TrainingModuleForm } from '../../../../components';

class CreateTrainingModule extends Component {

  constructor(props) {
    super(props);

    this.state = {
      segments: null,
      redirect: false,
      trainingModule: null,
    };
  }

  onSuccess = (trainingModule) => {
    setSuccess('Successfully created a Training Module!');

    this.setState({
      redirect: true,
      trainingModule: trainingModule
    });
  };

  render() {
    if (this.state.redirect && this.state.trainingModule != null) {
      return <Redirect to={'/training/training-modules/' + this.state.trainingModule.id} />;
    }

    return (
      <Container>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/training/training-modules'} description='Training Modules' />
        </div>

        <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Create Training Module</Typography>

        <TrainingModuleForm onSuccess={this.onSuccess} />
      </Container>
    );
  }
}

export default CreateTrainingModule;
