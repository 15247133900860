import { combineReducers } from 'redux';

// Reducers
import authReducer from './authReducer';
import alertReducer from './alertReducer';
import confirmReducer from './confirmReducer';

export default combineReducers({
  auth: authReducer,
  alert: alertReducer,
  confirm: confirmReducer
});
