import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// Alerts
import { setError, setSuccess } from '../../../../alerts';

import { getTrainingModule, toggleTrainingModuleActive } from '../../../../api';

// Components
import { LineItem } from '@lexcelon/react-util';
import { BackToButton, TrainingModuleForm } from '../../../../components';
import { ROUSING_RED } from '@ergonauts/ergo-algo-react/core/constants';

class TrainingModule extends Component {
  constructor(props) {
    super(props);

    this.state = {
      trainingModule: null,
      editMode: false,
      redirect: false
    };

    this.onEditSuccess = this.onEditSuccess.bind(this);
    this.loadTrainingModule = this.loadTrainingModule.bind(this);
    this.toggleTrainingModuleStatus = this.toggleTrainingModuleStatus.bind(this);
  }

  componentDidMount() {
    this.loadTrainingModule();
  }

  loadTrainingModule() {
    const trainingModuleId = this.props.match?.params?.id;
    getTrainingModule(trainingModuleId).then((trainingModule) => {
      this.setState({
        trainingModule: trainingModule
      });
    }).catch((error) => {
      setError(error ?? 'Unable to load the requested training module.');

      this.setState({
        redirect: true
      });
    });
  }

  onEditSuccess() {
    this.setState({
      editMode: false
    });

    setSuccess('Successfully updated the training module.');

    // Reload the training module
    this.loadTrainingModule();
  }

  toggleTrainingModuleStatus() {
    const newStatus = !this.state.trainingModule.isActive;

    toggleTrainingModuleActive({ id: this.state.trainingModule.id, isActive: newStatus }).then(() => {
      setSuccess('Successfully ' + (newStatus ? 'activated' : 'deactivated') + ' the training module.');

      this.loadTrainingModule();
    }).catch((error) => {
      setError(error ?? 'Unable to toggle training module status, please try again.');
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/training/training-modules'} />;
    }

    if (this.state.editMode) {
      return (
        <Container style={{ paddingTop: '20px' }}>
          <BackToButton to={'/training/training-modules'} description='Training Modules' />

          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Edit Training Module</Typography>
          <TrainingModuleForm
            onSuccess={this.onEditSuccess}
            trainingModule={this.state.trainingModule}
            onClose={() => this.setState({ editMode: false })}
          />
        </Container>
      );
    }

    return (
      <Container style={{ paddingTop: '20px' }}>
        <BackToButton to={'/training/training-modules'} description='Training Modules' />

        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.trainingModule?.getName()}</Typography>
        <Typography variant='h6' style={{ textAlign: 'center' }}>Training Module</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: ROUSING_RED, fontStyle: 'italic' }}>{this.state.trainingModule?.getIsActive() ? 'Active' : 'Inactive'}</Typography>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5em', marginBottom: '0.3em' }}>
          <img src={this.state.trainingModule?.getDisplayImageUrl()} style={{ width: '150px', height: 'auto' }} />
        </div>

        <LineItem
          value={this.state.trainingModule?.getName()}
          description='Name'
        />

        <LineItem
          value={this.state.trainingModule?.getSegment()?.getName()}
          description='Segment'
        />

        <LineItem
          value={this.state.trainingModule?.getDescription()}
          description='Description'
        />

        <LineItem
          value={this.state.trainingModule?.getNotes()}
          description='Notes'
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            style={{
              margin: '10px',
              padding: '10px',
              width: (window.innerWidth < 700 ? '80%' : '40%')
            }}
            onClick={() => {
              this.setState({
                editMode: true
              });
            }}
            secondary
          >
            Edit Training Module
          </Button>

          <Button
            style={{
              margin: '10px',
              padding: '10px',
              width: (window.innerWidth < 700 ? '80%' : '40%')
            }}
            onClick={() => {
              this.toggleTrainingModuleStatus();
            }}
            danger
          >
            {this.state.trainingModule?.getIsActive() ? 'Set Inactive' : 'Set Active'}
          </Button>
        </div>

      </Container>
    );
  }
}

TrainingModule.propTypes = {
  match: PropTypes.any
};

export default withRouter(TrainingModule);
