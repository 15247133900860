import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Redirect, Link } from 'react-router-dom';
import { Container, Typography } from '@mui/material';
import { Button } from '@lexcelon/react-util';

// Alerts
import { setError, setSuccess } from '../../../alerts';
import { confirm, startConfirmLoading, stopConfirmLoading, closeConfirm } from '../../../alerts/confirm';

import { getGroup, toggleGroupActive } from '../../../api';

// Components
import { LineItem } from '@lexcelon/react-util';
import { BackToButton, GroupForm } from '../../../components';

// Constants
import { ROUSING_RED, FULL_DATE_TIME } from '@ergonauts/ergo-algo-react/core/constants';

// Icons
import VisibilityIcon from '@mui/icons-material/Visibility';

class Group extends Component {
  constructor(props) {
    super(props);

    this.state = {
      group: null,
      editMode: false,
      redirect: false,
    };

    this.onEditSuccess = this.onEditSuccess.bind(this);
    this.loadGroup = this.loadGroup.bind(this);
    this.toggleGroupActive = this.toggleGroupActive.bind(this);
  }

  componentDidMount() {
    this.loadGroup();
  }

  loadGroup() {
    const groupId = this.props.match?.params?.id;
    getGroup(groupId).then((group) => {
      this.setState({ group: group });
    }).catch((error) => {
      setError(error ?? 'Error: Unable to load that requested group.');

      this.setState({
        redirect: true
      });
    });
  }

  onEditSuccess() {
    this.setState({ editMode: false });
    this.loadGroup();
  }

  toggleGroupActive() {
    const newStatus = !this.state.group.getIsActive();
    if (newStatus) {
      toggleGroupActive({ groupId: this.state.group.id, isActive: newStatus }).then(() => {
        setSuccess('Successfully ' + (newStatus ? 'activated' : 'deactivated') + ' the group.');
        this.loadGroup();
      }).catch((error) => {
        setError(error ?? 'Error: Unable to toggle group status, please try again.');
      });
    }
    else {
      this.deactivateGroup(this.state.group.id, newStatus);
    }
  }

  deactivateGroup = (groupId, newStatus) => {
    confirm({
      title: 'Are you sure you want to deactivate this group?',
      body: 'Any scheduled or confirmed evaluations will be cancelled and any evaluees will be notified. You cannot undo this.',
      onConfirm: () => {
        startConfirmLoading();
        toggleGroupActive({ groupId: groupId, isActive: newStatus }).then(() => {
          setSuccess('Successfully deactivated the group.');
          this.loadGroup();
          stopConfirmLoading();
          closeConfirm();
        }).catch(error => {
          setError(error ?? 'Error: Unable to deactivate group.');
          stopConfirmLoading();
        });
      },
      danger: true
    });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={'/groups'} />;
    }

    if (this.state.editMode) {
      return (
        <Container style={{ paddingTop: '20px' }}>
          <BackToButton to='/groups' description='Groups' />
          <Typography variant='h1' style={{ textAlign: 'center', marginBottom: '1em', marginTop: '1.5em' }}>Edit Group</Typography>
          <GroupForm
            onSuccess={this.onEditSuccess}
            group={this.state.group}
            onClose={() => this.setState({ editMode: false })}
          />
        </Container>
      );
    }

    return (
      <Container style={{ paddingTop: '20px' }}>
        <BackToButton to='/groups' description='Groups' />
        <Typography variant='h1' style={{ textAlign: 'center', marginTop: '1em' }}>{this.state.group?.getName()}</Typography>
        <Typography variant='subtitle1' style={{ textAlign: 'center', color: ROUSING_RED, fontStyle: 'italic' }}>{this.state.group?.getIsActive() ? 'Active' : 'Inactive'}</Typography>

        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '0.5em', marginBottom: '0.3em' }}>
          <img src={this.state.group?.getLogoUrl()} style={{ width: '150px', height: 'auto' }} />
        </div>

        <LineItem
          value={this.state.group?.getContractExpiration()?.toLocaleString(FULL_DATE_TIME)}
          description='Contract Expiration Date'
        />

        <LineItem
          value={this.state.group?.getContractMaxEvaluators()}
          description='Max Number of Evaluators (in Contract)'
        />

        <LineItem
          value={this.state.group?.getContractMaxEvaluations()}
          description='Max Number of Evaluations (in Contract)'
        />

        <LineItem
          value={this.state.group?.getAddress()?.getFullAddress()}
          description='Group Location'
        />

        <LineItem
          value={this.state.group?.getContactName()}
          description='Contact Name'
        />

        <LineItem
          value={this.state.group?.getContactPhoneNumber()}
          description='Contact Phone Number'
        />

        <LineItem
          value={this.state.group?.getContactEmail()}
          description='Contact Email'
        />

        <LineItem
          value={this.state.group?.getEvalueeCanViewReport() ? 'Yes' : 'No'}
          description='Can Evaluee View Report'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getAdminCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/admins'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Admins'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getEvaluatorCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/evaluators'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Evaluators'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getEvaluationCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/evaluations'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Evaluations'
        />

        <LineItem
          value={
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant='body1' style={{ marginRight: 10 }}>{this.state.group?.getInvitationCount()}</Typography>
              <Link to={'/groups/' + this.props.match?.params?.id + '/invitations'}><VisibilityIcon /></Link>
            </div>
          }
          description='Number of Outstanding Invitations'
        />

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {this.state.group?.getIsIndependent() == false &&
            <Button
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.setState({
                  editMode: true
                });
              }}
              secondary
            >
              Edit Group
            </Button>}

          {this.state.group?.getIsIndependent() == false &&
            <Button
              style={{
                margin: '10px',
                padding: '10px',
                width: (window.innerWidth < 700 ? '80%' : '40%')
              }}
              onClick={() => {
                this.toggleGroupActive();
              }}
              danger
            >
              Set {this.state.group?.getIsActive() ? 'Inactive' : 'Active'}
            </Button>}
        </div>

      </Container>
    );
  }
}

Group.propTypes = {
  match: PropTypes.any
};

export default withRouter(Group);
