import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

// Components
import { BackToButton, EvaluatorTrainingTable } from '../../../../../components';

// API
import { getPerson, listEvaluatorTrainings } from '../../../../../api';

// Alerts
import { setError } from '../../../../../alerts';

class PersonTraining extends Component {
  constructor(props) {
    super(props);

    this.state = {
      training: [],
      person: null,
      isLoading: false
    };
  }

  componentDidMount() {
    const personId = this.props.match?.params?.id;

    // Get person's info
    getPerson(personId).then(person => {
      this.setState({ person: person });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve person.');
    });

    // Get the evaluator trainings
    this.setState({ isLoading: true });
    listEvaluatorTrainings({ evaluatorId: personId }).then(({ results }) => {
      if (results != null) {
        this.setState({ training: results });
      }
      this.setState({ isLoading: false });
    }).catch((error) => {
      setError(error ?? 'Error: Could not retrieve user training modules.');
      this.setState({ isLoading: false });
    });
  }

  render() {
    const personId = this.props.match?.params?.id;
    return (
      <>
        <div style={{ marginTop: '20px', marginLeft: '24px' }} >
          <BackToButton to={'/people/' + personId} description={this.state.person?.getFullName()} />
        </div>
        <EvaluatorTrainingTable training={this.state.training} isLoading={this.state.isLoading} />
      </>
    );
  }
}

PersonTraining.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(PersonTraining);
